import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';

const Login = props => {
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe] = useState(localStorage.getItem('rememberMe') === 'true');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px', // Add padding around the content
      textAlign: 'center', // Center text for all children
      fontFamily: fontFamilies.regular,
      height: '80vh',
    },
    ImageContainer: {
      width: '100%', // Use full width of the screen
      height: '190px', // Adjust the height as needed
      backgroundImage: 'url(./image.jpeg)',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    Text: {
      fontFamily: fontFamilies.bold,
      margin: '20px 0', // Add some margin around text
    },
    SubText: {
      fontFamily: fontFamilies.regular,
      margin: '10px 0', // Add some margin around subtext
    },
    UserInput: {
      width: '100%', // Use full width of the screen
      height: '40px',
      margin: '10px 0', // Add some margin around inputs
      padding: '0px 16px', // Increase padding for aesthetics
      border: '1px solid #ccc', // Add border to match the design
      boxSizing: 'border-box',
      borderRadius: '20px', // Rounded corners
      boxShadow: 'none', // Remove box shadow to match the design
      backgroundColor: '#ffffff',
      fontFamily: fontFamilies.light,
    },
    link: {
      color: '#007bff', // Bootstrap default link color
      textDecoration: 'none', // No underline for links
      cursor: 'pointer', // Cursor pointer for all links
      marginTop: '15px', // Space between login button and links
      fontFamily: fontFamilies.light,
    },
    checkboxContainer: {
      alignSelf: 'flex-start', // Align checkbox to the start of flex container
      display: 'flex',
      alignItems: 'center', // Center checkbox label vertically
      marginTop: '10px', // Space between password input and checkbox
    },
    checkbox: {
      marginRight: '8px', // Space between checkbox and its label
    },
    LoginLabel: {
      width: '100%', // Use full width of the screen
      height: '40px',
      marginTop: '20px', // Add some margin at the top
      padding: '0px 16px',
      border: 'none',
      borderRadius: '20px', // Rounded corners
      backgroundColor: '#2A1662',
      color: '#ffffff',
      fontFamily: fontFamilies.semiBold,
      cursor: 'pointer', // Add cursor pointer for button
    },
  };

  const defaultProps = {
    image:
      'https://images.unsplash.com/photo-1494319827402-c4b839aed26b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDUzMDJ8MHwxfHNlYXJjaHwzN3x8Z2lmdCUyMG1lbWJlcnxlbnwxfHx8fDE3MDYzNjQ5OTJ8MA&ixlib=rb-4.0.3&q=80&w=1080',
    text: translate(language, 'login.welcomeBack'),
    subText: translate(language, 'login.signInToAccess'),
    userInput: translate(language, 'login.email'),
    userPassword: translate(language, 'login.password'),
  };

  const validateEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const LoginButton = ({onClick, text}) => {
    return (
      <button style={styles.LoginLabel} onClick={onClick}>
        {text}
      </button>
    );
  };

  const handleLogin = async () => {
    setError('');
    setLoading(true);

    if (!validateEmail(email)) {
      setError(translate(language, 'login.invalidEmail'));
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/login`,
        {userEmail: email, userPassword: password},
      );

      // Assume response contains token and userInfo

      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem(
          'userInfo',
          JSON.stringify(response.data.userInfo),
        );

        if (rememberMe) {
          localStorage.setItem('rememberEmail', email);
          localStorage.setItem('rememberPassword', password);
          localStorage.setItem('rememberMe', true);
        } else {
          localStorage.removeItem('rememberEmail');
          localStorage.removeItem('rememberPassword');
          localStorage.removeItem('rememberMe');
        }

        navigate('/');
      } else {
        // Handle case where login response does not contain a token
        setError('Login failed. No token received.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(
        error.response?.data?.message || 'Failed to login. Please try again.',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleBiometricLogin = async () => {
    const base64CredentialId = localStorage.getItem('credentialId');
    if (!base64CredentialId) {
      alert(
        'No biometric credentials registered. Please enable biometric login in settings.',
      );
      return;
    }

    try {
      setLoading(true);
      // Convert Base64 to binary for use
      const credentialId = Uint8Array.from(atob(base64CredentialId), c =>
        c.charCodeAt(0),
      );

      const publicKey = {
        challenge: new Uint8Array([
          ...window.crypto.getRandomValues(new Uint8Array(32)),
        ]),
        allowCredentials: [{type: 'public-key', id: credentialId}],
        userVerification: 'required',
        timeout: 60000,
      };

      const assertion = await navigator.credentials.get({publicKey});
      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/biometric_verify`,
        {
          id: assertion.id,
          rawId: btoa(String.fromCharCode(...new Uint8Array(assertion.rawId))),
          type: assertion.type,
          response: {
            clientDataJSON: btoa(
              String.fromCharCode(
                ...new Uint8Array(assertion.response.clientDataJSON),
              ),
            ),
            authenticatorData: btoa(
              String.fromCharCode(
                ...new Uint8Array(assertion.response.authenticatorData),
              ),
            ),
            signature: btoa(
              String.fromCharCode(
                ...new Uint8Array(assertion.response.signature),
              ),
            ),
            userHandle: btoa(
              String.fromCharCode(
                ...new Uint8Array(assertion.response.userHandle || []),
              ),
            ),
          },
        },
      );
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem(
          'userInfo',
          JSON.stringify(response.data.userInfo),
        ); // Assuming the userInfo object is part of the response
        navigate('/');
      } else {
        setError(translate(language, 'login.serverAuthenticationFailed'));
      }
    } catch (error) {
      console.error('Biometric login error:', error);
      alert(error);
      setError(
        translate(language, 'login.biometricFailed') +
          (error.response?.data?.message ||
            translate(language, 'login.tryAgain')),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div style={styles.container}>
        <div style={styles.Text}>{props.text ?? defaultProps.text}</div>
        <div style={styles.SubText}>
          {props.subText ?? defaultProps.subText}
        </div>
        {error && <div style={{color: 'red'}}>{error}</div>}
        <input
          style={styles.UserInput}
          type="text"
          placeholder={props.userInput ?? defaultProps.userInput}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <input
          style={styles.UserInput}
          type="password"
          placeholder={props.userPassword ?? defaultProps.userPassword}
          value={password}
          onChange={e => setPassword(e.target.value)}
        />

        <div style={styles.checkboxContainer}>
          <input type="checkbox" style={styles.checkbox} id="rememberMe" />
          <label htmlFor="rememberMe">Remember me</label>
        </div>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <LoginButton
              onClick={handleLogin}
              text={translate(language, 'login.logIn')}
            />
            <LoginButton
              onClick={handleBiometricLogin}
              text={translate(language, 'login.biometricLogin')}
            />
          </>
        )}
        <div
          style={styles.link}
          onClick={() =>
            navigate('/forgotPassword', {state: {showBackIcon: true}})
          }>
          {translate(language, 'login.forgotPassword')}
        </div>
        <div
          style={styles.link}
          onClick={() => navigate('/signup', {state: {showBackIcon: true}})}>
          {translate(language, 'login.notAMember')}
        </div>
      </div>
    </>
  );
};

export default Login;
