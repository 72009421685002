import {useState} from 'react';
import axios from 'axios';
import {useNavigate, useLocation} from 'react-router-dom';
import {
  MenuItem,
  FormControlLabel,
  InputLabel,
  Select,
  FormControl,
  CircularProgress,
  Checkbox,
} from '@mui/material';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';

const categories = [
  'Art',
  'Wine',
  'Tea',
  'Luxury',
  'Antique',
  'Service',
  'Others',
];
const countries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic (CAR)',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czechia (Czech Republic)',
  'Democratic Republic of the Congo',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar (formerly Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Korea',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine State',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (UAE)',
  'United Kingdom (UK)',
  'United States of America (USA)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City (Holy See)',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

const RegProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {showBackIcon} = location.state || {};

  const {fontFamilies} = useFontFamilyByLanguage();

  const [category, setCategory] = useState('');
  const [productImages, setProductImages] = useState([]);
  const [certificationFile, setCertificationFile] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [origin, setOrigin] = useState('');
  const [minOrderQuantity, setMinOrderQuantity] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleImageUpload = event => {
    const files = Array.from(event.target.files);
    if (files.length + productImages.length > 6) {
      setError('You can only upload up to 6 images.');
      return;
    }
    files.forEach(file => {
      if (file.size > 10 * 1024 * 1024) {
        setError('Each image must be less than 10MB.');
        return;
      }
    });
    setProductImages(prevImages => [...prevImages, ...files]);
    setError('');
  };

  const handleCertificationUpload = event => {
    const file = event.target.files[0];
    if (file.size > 10 * 1024 * 1024) {
      setError('The certification file must be less than 10MB.');
      return;
    }
    setCertificationFile(file);
    setError('');
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    setError('');

    const formData = new FormData();
    formData.append('category', category);
    productImages.forEach((image, index) => {
      formData.append(`productImages[${index}]`, image);
    });
    if (certificationFile) {
      formData.append('certificationFile', certificationFile);
    }
    formData.append('name', name);
    formData.append('description', description);
    formData.append('origin', origin);
    formData.append('minOrderQuantity', minOrderQuantity);
    formData.append('unitPrice', unitPrice);

    try {
      await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/api/redeem/productListing`,
        formData,
      );
      navigate('/explorer', {state: {showBackIcon: true}});
    } catch (error) {
      setError('Failed to register product. Please try again.');
      console.error('Error registering product:', error);
    } finally {
      setLoading(false);
    }
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      textAlign: 'center',
      fontFamily: fontFamilies.regular,
      height: '80vh',
    },
    UserInput: {
      width: '100%',
      height: '40px',
      margin: '10px 0',
      padding: '0px 16px',
      border: '1px solid #ccc',
      boxSizing: 'border-box',
      borderRadius: '20px',
      boxShadow: 'none',
      backgroundColor: '#ffffff',
      fontFamily: fontFamilies.regular,
    },
    FileInput: {
      width: '100%',
      margin: '10px 0',
      padding: '10px 16px',
      border: '1px solid #ccc',
      boxSizing: 'border-box',
      borderRadius: '20px',
      boxShadow: 'none',
      backgroundColor: '#ffffff',
      fontFamily: fontFamilies.regular,
    },
    SubmitButton: {
      width: '100%',
      height: '40px',
      marginTop: '20px',
      padding: '0px 16px',
      border: 'none',
      borderRadius: '20px',
      backgroundColor: '#2A1662',
      color: '#ffffff',
      fontFamily: fontFamilies.semiBold,
      cursor: 'pointer',
    },
    ImagePreview: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    PreviewImage: {
      width: '100px',
      height: '100px',
      objectFit: 'cover',
      margin: '5px',
    },
  };

  return (
    <>
      <TopNav text="Product Register" />
      <div style={styles.container}>
        <form
          onSubmit={handleSubmit}
          style={{width: '100%', maxWidth: '600px'}}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Category</InputLabel>
            <Select
              value={category}
              onChange={e => setCategory(e.target.value)}
              style={styles.UserInput}>
              {categories.map(cat => (
                <MenuItem key={cat} value={cat}>
                  {cat}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div>
            <InputLabel>Upload Product Images (up to 6)</InputLabel>
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageUpload}
              style={styles.FileInput}
            />
            {productImages.length > 0 && (
              <div style={styles.ImagePreview}>
                {productImages.map((image, index) => (
                  <img
                    key={index}
                    src={URL.createObjectURL(image)}
                    alt="Product"
                    style={styles.PreviewImage}
                  />
                ))}
              </div>
            )}
          </div>
          <div>
            <InputLabel>Upload Certification (image or PDF)</InputLabel>
            <input
              type="file"
              accept="image/*,application/pdf"
              onChange={handleCertificationUpload}
              style={styles.FileInput}
            />
          </div>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={e => setName(e.target.value)}
            style={styles.UserInput}
          />
          <input
            type="text"
            placeholder="Description"
            value={description}
            onChange={e => setDescription(e.target.value)}
            style={styles.UserInput}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Origin</InputLabel>
            <Select
              value={origin}
              onChange={e => setOrigin(e.target.value)}
              style={styles.UserInput}>
              {countries.map(country => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <input
            type="number"
            placeholder="Minimum Order Quantity"
            value={minOrderQuantity}
            onChange={e => setMinOrderQuantity(e.target.value)}
            style={styles.UserInput}
          />
          <input
            type="number"
            placeholder="Unit Price"
            value={unitPrice}
            onChange={e => setUnitPrice(e.target.value)}
            style={styles.UserInput}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={e => setTermsAccepted(e.target.checked)}
              />
            }
            label="I accept the Terms and Conditions"
          />
          {error && <p style={{color: 'red'}}>{error}</p>}
          {loading ? (
            <CircularProgress />
          ) : (
            <button type="submit" style={styles.SubmitButton}>
              Submit
            </button>
          )}
        </form>
      </div>
      <BottomNav />
    </>
  );
};

export default RegProduct;
