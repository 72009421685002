import {useState} from 'react';
import axios from 'axios';
import {useNavigate, useLocation} from 'react-router-dom';
import {
  FormControlLabel,
  InputLabel,
  CircularProgress,
  Checkbox,
} from '@mui/material';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import Web3 from 'web3';

const RegProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {showBackIcon} = location.state || {};

  const {fontFamilies} = useFontFamilyByLanguage();

  const [category, setCategory] = useState('');
  const [productImages, setProductImages] = useState([]);
  const [certificationFile, setCertificationFile] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [origin, setOrigin] = useState('');
  const [minOrderQuantity, setMinOrderQuantity] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [nftImages, setNftImages] = useState([]);
  const [selectedNft, setSelectedNft] = useState(null);
  const [walletConnected, setWalletConnected] = useState(false);
  const [web3, setWeb3] = useState(null);
  const [userAssets, setUserAssets] = useState([]);
  const [selectedContract, setSelectedContract] = useState('');

  const connectWallet = async () => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      try {
        await window.ethereum.enable();
        setWeb3(web3Instance);
        setWalletConnected(true);
        fetchUserAssets();
      } catch (error) {
        console.error('User denied account access', error);
      }
    } else {
      console.error(
        'Non-Ethereum browser detected. You should consider trying MetaMask!',
      );
    }
  };

  const fetchUserAssets = async () => {
    if (!web3) return;
    const accounts = await web3.eth.getAccounts();
    const userAddress = accounts[0];

    // Replace with actual API or logic to fetch user assets
    const assets = await fetchUserNFTContracts(userAddress);

    setUserAssets(assets);
  };

  const fetchUserNFTContracts = async userAddress => {
    // Example logic to fetch user NFT contracts, replace with actual API or logic
    return [
      '0xYourContractAddress1',
      '0xYourContractAddress2',
      '0xYourContractAddress3',
    ];
  };

  const fetchNFTs = async () => {
    if (!web3 || !selectedContract) return;
    const accounts = await web3.eth.getAccounts();
    const userAddress = accounts[0];

    const contractABI = []; // Your contract ABI

    const contract = new web3.eth.Contract(contractABI, selectedContract);
    const balance = await contract.methods.balanceOf(userAddress).call();

    const nfts = [];
    for (let i = 0; i < balance; i++) {
      const tokenId = await contract.methods
        .tokenOfOwnerByIndex(userAddress, i)
        .call();
      const tokenURI = await contract.methods.tokenURI(tokenId).call();
      nfts.push(tokenURI);
    }

    setNftImages(nfts);
  };

  const handleImageUpload = event => {
    const files = Array.from(event.target.files);
    if (files.length + productImages.length > 1) {
      setError('You can only upload up to 1 image.');
      return;
    }
    files.forEach(file => {
      if (file.size > 10 * 1024 * 1024) {
        setError('Each image must be less than 10MB.');
        return;
      }
    });
    setProductImages(prevImages => [...prevImages, ...files]);
    setError('');
  };

  const handleCertificationUpload = event => {
    const file = event.target.files[0];
    if (file.size > 10 * 1024 * 1024) {
      setError('The certification file must be less than 10MB.');
      return;
    }
    setCertificationFile(file);
    setError('');
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    setError('');

    const formData = new FormData();
    formData.append('category', category);
    productImages.forEach((image, index) => {
      formData.append(`productImages[${index}]`, image);
    });
    if (selectedNft) {
      formData.append('nftImage', selectedNft);
    }
    if (certificationFile) {
      formData.append('certificationFile', certificationFile);
    }
    formData.append('name', name);
    formData.append('description', description);
    formData.append('origin', origin);
    formData.append('minOrderQuantity', minOrderQuantity);
    formData.append('unitPrice', unitPrice);

    try {
      await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/api/redeem/logoListing`,
        formData,
      );
      navigate('/explorer', {state: {showBackIcon: true}});
    } catch (error) {
      setError('Failed to register product. Please try again.');
      console.error('Error registering product:', error);
    } finally {
      setLoading(false);
    }
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      textAlign: 'center',
      fontFamily: fontFamilies.regular,
      height: '80vh',
    },
    UserInput: {
      width: '100%',
      height: '40px',
      margin: '10px 0',
      padding: '0px 16px',
      border: '1px solid #ccc',
      boxSizing: 'border-box',
      borderRadius: '20px',
      boxShadow: 'none',
      backgroundColor: '#ffffff',
      fontFamily: fontFamilies.regular,
    },
    FileInput: {
      width: '100%',
      margin: '10px 0',
      padding: '10px 16px',
      border: '1px solid #ccc',
      boxSizing: 'border-box',
      borderRadius: '20px',
      boxShadow: 'none',
      backgroundColor: '#ffffff',
      fontFamily: fontFamilies.regular,
    },
    SubmitButton: {
      width: '100%',
      height: '40px',
      marginTop: '20px',
      padding: '0px 16px',
      border: 'none',
      borderRadius: '20px',
      backgroundColor: '#2A1662',
      color: '#ffffff',
      fontFamily: fontFamilies.semiBold,
      cursor: 'pointer',
    },
    ImagePreview: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    PreviewImage: {
      width: '100px',
      height: '100px',
      objectFit: 'cover',
      margin: '5px',
    },
    NftButton: {
      width: '100%',
      height: '40px',
      margin: '10px 0',
      padding: '0px 16px',
      border: '1px solid #ccc',
      borderRadius: '20px',
      cursor: 'pointer',
      backgroundColor: '#ffffff',
      fontFamily: fontFamilies.regular,
    },
    Select: {
      width: '100%',
      height: '40px',
      margin: '10px 0',
      padding: '0px 16px',
      border: '1px solid #ccc',
      boxSizing: 'border-box',
      borderRadius: '20px',
      boxShadow: 'none',
      backgroundColor: '#ffffff',
      fontFamily: fontFamilies.regular,
    },
  };

  return (
    <>
      <TopNav text="Logo Register" />
      <div style={styles.container}>
        <form
          onSubmit={handleSubmit}
          style={{width: '100%', maxWidth: '600px'}}>
          <div>
            <InputLabel>Upload Logo Image</InputLabel>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={styles.FileInput}
            />
            {productImages.length > 0 && (
              <div style={styles.ImagePreview}>
                {productImages.map((image, index) => (
                  <img
                    key={index}
                    src={URL.createObjectURL(image)}
                    alt="Logo"
                    style={styles.PreviewImage}
                  />
                ))}
              </div>
            )}
          </div>
          <div>
            <InputLabel>OR Select Your NFT</InputLabel>
            <button
              type="button"
              onClick={connectWallet}
              style={styles.NftButton}>
              {walletConnected ? 'Wallet Connected' : 'Connect Wallet'}
            </button>
            {walletConnected && (
              <>
                <InputLabel>Select NFT Contract</InputLabel>
                <select
                  value={selectedContract}
                  onChange={e => setSelectedContract(e.target.value)}
                  style={styles.Select}>
                  <option value="" disabled>
                    Select NFT Contract
                  </option>
                  {userAssets.map((contract, index) => (
                    <option key={index} value={contract}>
                      {contract}
                    </option>
                  ))}
                </select>
                <button
                  type="button"
                  onClick={fetchNFTs}
                  style={styles.NftButton}
                  disabled={!selectedContract}>
                  Fetch My NFTs
                </button>
              </>
            )}
            {nftImages.length > 0 && (
              <div style={styles.ImagePreview}>
                {nftImages.map((nft, index) => (
                  <img
                    key={index}
                    src={nft}
                    alt={`NFT ${index + 1}`}
                    style={styles.PreviewImage}
                    onClick={() => setSelectedNft(nft)}
                  />
                ))}
              </div>
            )}
            {selectedNft && (
              <p>
                Selected NFT Image:{' '}
                <a href={selectedNft} target="_blank" rel="noopener noreferrer">
                  {selectedNft}
                </a>
              </p>
            )}
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={e => setTermsAccepted(e.target.checked)}
              />
            }
            label="I accept the Terms and Conditions"
          />
          {error && <p style={{color: 'red'}}>{error}</p>}
          {loading ? (
            <CircularProgress />
          ) : (
            <button type="submit" style={styles.SubmitButton}>
              Submit
            </button>
          )}
        </form>
      </div>
      <BottomNav />
    </>
  );
};

export default RegProduct;
