import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';

const BottomNav = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);

  const [isMobile, setIsMobile] = useState(
    window.innerWidth / window.innerHeight < 1,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth / window.innerHeight < 1);
    };

    // Set up event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const styles = {
    placeholder: {
      height: '56px',
      paddingTop: '50px',
    },
    Bar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      height: '76px',
      backgroundColor: '#ffffff',
      borderTop: '1px solid #f0f0f0',
      boxSizing: 'border-box',
      boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
      minWidth: '300px',
      zIndex: 1000,
    },
    Icons: {
      color: '#030303',
      fill: '#030303',
      fontSize: '35px',
      width: '35px',
      height: '30px',
      opacity: 1,
      paddingBottom: '6px',
    },
    IconContainer: {
      // Style for the container of the icon and text
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    IconText: {
      // Style for the text under the icon
      fontFamily: fontFamilies.light,
      fontSize: '10px',
      opacity: 1,
    },
    ImageContainer: {
      marginTop: '-40px',
      width: '88px',
      height: '98px',
      borderRadius: '33px',
      backgroundImage: 'url(./image.jpeg)',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      zIndex: 1,
    },
  };

  const HomeIconComponent = () => {
    return (
      <div style={styles.IconContainer} onClick={() => navigate('/')}>
        <svg style={styles.Icons} viewBox="0 0 24 24">
          <path
            fill={location.pathname === '/' ? '#2A1662' : '#506CAA'}
            d="M12.6387 26.1109H7.08312C6.6896 26.1109 6.35974 25.9778 6.09353 25.7116C5.82733 25.4454 5.69423 25.1155 5.69423 24.722V14.9998H3.33312C3.00904 14.9998 2.78913 14.8493 2.67339 14.5484C2.55765 14.2475 2.6271 13.9928 2.88173 13.7845L14.479 3.33312C14.7336 3.07849 15.0461 2.95117 15.4165 2.95117C15.7868 2.95117 16.0993 3.07849 16.354 3.33312L20.972 7.49978V4.58312C20.972 4.39793 21.0415 4.23589 21.1803 4.09701C21.3192 3.95812 21.4813 3.88867 21.6665 3.88867H24.4442C24.6294 3.88867 24.7915 3.95812 24.9303 4.09701C25.0692 4.23589 25.1387 4.39793 25.1387 4.58312V11.2498L27.9512 13.7845C28.2058 13.9928 28.2752 14.2475 28.1595 14.5484C28.0438 14.8493 27.8239 14.9998 27.4998 14.9998H25.1387V24.722C25.1387 25.1155 25.0056 25.4454 24.7394 25.7116C24.4732 25.9778 24.1433 26.1109 23.7498 26.1109H18.1942V19.1665C18.1942 18.7729 18.0611 18.4431 17.7949 18.1769C17.5287 17.9107 17.1989 17.7776 16.8053 17.7776H14.0276C13.634 17.7776 13.3042 17.9107 13.038 18.1769C12.7718 18.4431 12.6387 18.7729 12.6387 19.1665V26.1109ZM12.6387 12.2567H18.1942C18.1942 11.516 17.9165 10.9083 17.3609 10.4338C16.8053 9.95927 16.1572 9.72201 15.4165 9.72201C14.6757 9.72201 14.0276 9.95927 13.472 10.4338C12.9165 10.9083 12.6387 11.516 12.6387 12.2567Z"></path>
        </svg>
        <span
          style={{
            ...styles.IconText,
            color: location.pathname === '/' ? '#2A1662' : '#506CAA',
          }}>
          {translate(language, 'bottomNav.home')}
        </span>
      </div>
    );
  };

  const ExploreComponent = () => {
    return (
      <div style={styles.IconContainer} onClick={() => navigate('/explorer')}>
        <svg style={styles.Icons} viewBox="0 0 24 24">
          <path
            fill={location.pathname === '/explorer' ? '#2A1662' : '#506CAA'}
            strokeWidth="5"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-5.5-2.5 7.51-3.49L17.5 6.5 9.99 9.99 6.5 17.5zm5.5-6.6c.61 0 1.1.49 1.1 1.1s-.49 1.1-1.1 1.1-1.1-.49-1.1-1.1.49-1.1 1.1-1.1z"></path>
        </svg>
        <span
          style={{
            ...styles.IconText,
            color: location.pathname === '/explorer' ? '#2A1662' : '#506CAA',
          }}>
          {translate(language, 'bottomNav.explorer')}
        </span>
      </div>
    );
  };
  const RewardsComponent = () => {
    return (
      <div style={styles.IconContainer} onClick={() => navigate('/redeem')}>
        <svg style={styles.Icons} viewBox="0 0 24 24">
          <path d="M0 0h24v24H0V0z" fill="none"></path>
          <path
            fill={location.pathname === '/redeem' ? '#2A1662' : '#506CAA'}
            d="M20 6h-2.18c.11-.31.18-.65.18-1a2.996 2.996 0 0 0-5.5-1.65l-.5.67-.5-.68C10.96 2.54 10.05 2 9 2 7.34 2 6 3.34 6 5c0 .35.07.69.18 1H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-5-2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM9 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm11 15H4v-2h16v2zm0-5H4V8h5.08L7 10.83 8.62 12 11 8.76l1-1.36 1 1.36L15.38 12 17 10.83 14.92 8H20v6z"></path>
        </svg>
        <span
          style={{
            ...styles.IconText,
            color: location.pathname === '/redeem' ? '#2A1662' : '#506CAA',
          }}>
          {translate(language, 'bottomNav.redeem')}
        </span>
      </div>
    );
  };

  const SavedComponent = () => {
    return (
      <div style={styles.IconContainer} onClick={() => navigate('/saved')}>
        <svg style={styles.Icons} viewBox="0 0 24 24">
          <path d="M0 0h24v24H0V0z" fill="none"></path>
          <path
            fill={location.pathname === '/saved' ? '#2A1662' : '#506CAA'}
            d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"></path>
        </svg>
        <span
          style={{
            ...styles.IconText,
            color: location.pathname === '/saved' ? '#2A1662' : '#506CAA',
          }}>
          {translate(language, 'bottomNav.saved')}
        </span>
      </div>
    );
  };

  const Image = props => {
    return (
      <div
        style={{
          ...styles.ImageContainer,
          backgroundImage: `url(${props.image ?? defaultProps.image})`,
        }}
        onClick={() => navigate('/profile')}
      />
    );
  };

  const defaultProps = {
    HomeIconComponent,
    ExploreComponent,
    RewardsComponent,
    SavedComponent,
    image: '/assets/icons/buttonIcon.png',
  };

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Adjust the styles based on the isMobile state
  const barStyle = {
    ...styles.Bar,
    display: isMobile ? 'flex' : 'none', // Hide the bar in desktop view
  };

  return (
    <>
      <div style={styles.placeholder} />
      <div style={barStyle}>
        {props.children}
        <div></div>
        <HomeIconComponent></HomeIconComponent>
        <ExploreComponent></ExploreComponent>
        <Image></Image>
        <RewardsComponent></RewardsComponent>
        <SavedComponent></SavedComponent>
        <div></div>
      </div>
    </>
  );
};

export default BottomNav;
