import InfoIcon from '@mui/icons-material/Info';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';

const TopNav = ({text = 'Title', info}) => {
  const {language} = useContext(LanguageContext);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth / window.innerHeight < 1,
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth / window.innerHeight < 1);
    };

    // Set up event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const {fontFamilies} = useFontFamilyByLanguage();

  const styles = {
    placeholder: {
      height: '60px',
      paddingBottom: '20px',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'fixed', // Fixed positioning relative to the viewport
      top: 0, // Align to the top of the viewport
      left: 0, // Align to the left of the viewport
      right: 0, // Align to the right of the viewport
      margin: 0,
      backgroundColor: '#2A1662',
      boxShadow: '0px 1px 12px rgba(193,193,193,0.25)',
      height: '80px', // Adjust the height as needed
      minWidth: '300px', // Minimum width set to 150px
      width: '100%',
      zIndex: 10, // Ensure it's above other elements
    },
    backIconContainer: {
      position: 'absolute',
      left: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      backgroundColor: 'white',
      cursor: 'pointer',
    },
    backIcon: {
      color: '#030303',
      width: '20px',
      height: '20px',
    },
    text: {
      fontFamily: fontFamilies.semiBold,
      color: 'white',
      fontSize: '20px',
      textAlign: 'center',
      flexGrow: 1, // This will push the icons to the sides
    },
    infoIconContainer: {
      position: 'absolute',
      right: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      //width: '40px',
      //height: '40px',
      //borderRadius: '50%',
      //backgroundColor: 'white',
      //cursor: 'pointer',
    },
    infoIcon: {
      color: 'white',
      width: '30px',
      height: '30px',
    },
  };

  const BackIcon = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const showBackIcon = location.state?.showBackIcon;

    if (!showBackIcon) {
      return null; // Don't render the BackIcon
    }

    return (
      <div style={styles.backIconContainer} onClick={() => navigate(-1)}>
        <svg style={styles.backIcon} viewBox="0 0 256 512">
          <path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"></path>
        </svg>
      </div>
    );
  };

  const handleInfoClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const InfoButton = () => {
    if (!info) {
      return null;
    }

    return (
      <div style={styles.infoIconContainer} onClick={handleInfoClick}>
        <InfoIcon style={styles.infoIcon} />
      </div>
    );
  };

  const containerStyle = {
    ...styles.container,
    display: isMobile ? 'flex' : 'none', // Hide on desktop, show on mobile
  };

  return (
    <>
      <div style={styles.placeholder} />
      <div style={containerStyle}>
        <BackIcon />
        <div style={styles.text}>{text}</div>
        <InfoButton />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{translate(language, 'infoDialog.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{info}</DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TopNav;
