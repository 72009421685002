// screens/Settings.js
import axios from 'axios';
import {useContext, useState} from 'react';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';

const Settings = () => {
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language, switchLanguage} = useContext(LanguageContext);
  const [biometricEnabled, setBiometricEnabled] = useState(
    localStorage.getItem('credentialId') ? true : false,
  );
  const [userInfo] = useState(
    JSON.parse(localStorage.getItem('userInfo')) || {},
  );

  const styles = {
    container: {
      margin: '30px',
      fontFamily: fontFamilies.regular,
      height: '80vh',
    },
    item: {
      marginTop: '30px',
      display: 'flex',
      alignItems: 'center',
    },
    checkIcon: {
      marginLeft: '10px',
      color: 'green', // Adjust color to fit your design
      fontSize: '20px', // Adjust size to fit your design
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    languageSwitch: {
      display: 'flex',
      alignItems: 'center',
    },
    languageButton: {
      cursor: 'pointer',
      padding: '5px 10px',
      backgroundColor: '#2A1662',
      border: 'none',
      borderRadius: '5px',
      color: 'white',
      fontFamily: fontFamilies.regular,
      marginLeft: '5px',
    },
    activeLanguageButton: {
      backgroundColor: '#2A1662',
    },
    inactiveLanguageButton: {
      backgroundColor: '#f0f0f0',
      color: '#2A1662',
    },
  };

  const handleLanguageSwitch = lang => {
    switchLanguage(lang);
    localStorage.setItem('Language', lang);
  };

  const handleEnableBiometricLogin = async () => {
    if (!window.PublicKeyCredential) {
      alert('Biometric login is not supported by this browser.');
      return;
    }

    try {
      const challenge = new Uint8Array(32);
      window.crypto.getRandomValues(challenge);

      const publicKey = {
        challenge: challenge,
        rp: {
          name: 'Boredtech',
        },
        user: {
          id: new Uint8Array(
            [...Array(16)].map(() => Math.floor(Math.random() * 256)),
          ),
          name: userInfo.firstName + ' ' + userInfo.lastName,
          displayName: userInfo.firstName,
          email: userInfo.email,
        },
        pubKeyCredParams: [{type: 'public-key', alg: -7}], // ES256
        timeout: 60000,
        authenticatorSelection: {
          authenticatorAttachment: 'platform',
          userVerification: 'required',
        },
        attestation: 'direct',
      };

      const credential = await navigator.credentials.create({publicKey});
      const credentialResponse = {
        id: credential.id,
        rawId: btoa(String.fromCharCode(...new Uint8Array(credential.rawId))),
        type: credential.type,
        response: {
          clientDataJSON: btoa(
            String.fromCharCode(
              ...new Uint8Array(credential.response.clientDataJSON),
            ),
          ),
          attestationObject: btoa(
            String.fromCharCode(
              ...new Uint8Array(credential.response.attestationObject),
            ),
          ),
        },
      };

      const token = localStorage.getItem('token');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/registerBiometric`,
        credentialResponse,
        config,
      );
      localStorage.setItem(
        'credentialId',
        btoa(String.fromCharCode(...new Uint8Array(credential.rawId))),
      );
      setBiometricEnabled(true);
      alert('Biometric login enabled successfully.');
    } catch (err) {
      console.error('Failed to create a credential:', err);
      alert('Error enabling biometric login: ' + err.message);
    }
  };

  return (
    <>
      <TopNav text={translate(language, 'settings.language')} />
      <div style={styles.container}>
        <div style={styles.header}>
          <div>{translate(language, 'settings.language')}</div>
          <div style={styles.languageSwitch}>
            <button
              style={{
                ...styles.languageButton,
                ...(language === 'en'
                  ? styles.activeLanguageButton
                  : styles.inactiveLanguageButton),
              }}
              onClick={() => handleLanguageSwitch('en')}>
              EN
            </button>
            <button
              style={{
                ...styles.languageButton,
                ...(language === 'zh'
                  ? styles.activeLanguageButton
                  : styles.inactiveLanguageButton),
              }}
              onClick={() => handleLanguageSwitch('zh')}>
              ZH
            </button>
            <button
              style={{
                ...styles.languageButton,
                ...(language === 'zhhk'
                  ? styles.activeLanguageButton
                  : styles.inactiveLanguageButton),
              }}
              onClick={() => handleLanguageSwitch('zhhk')}>
              ZHHK
            </button>
          </div>
        </div>
        <div style={styles.item} onClick={handleEnableBiometricLogin}>
          {translate(language, 'settings.enableBiometricLogin')}{' '}
          {biometricEnabled && <span style={styles.checkIcon}>&#x2714;</span>}
        </div>
      </div>
      <BottomNav />
    </>
  );
};

export default Settings;
