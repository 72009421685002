import {useLocation, useNavigate} from 'react-router-dom';
import BottomNav from '../Navigation/BottomNav';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';

const DynamicMessagePage = () => {
  const {fontFamilies} = useFontFamilyByLanguage();
  const location = useLocation();
  const navigate = useNavigate();

  let message = location.state?.message;
  let buttonText = location.state?.buttonText;
  let buttonLink = location.state?.buttonLink;
  if (!message || !buttonText || !buttonLink) {
    const queryParams = new URLSearchParams(location.search);
    message = message || queryParams.get('message');
    buttonText = buttonText || queryParams.get('buttonText');
    buttonLink = buttonLink || queryParams.get('buttonLink');
  }

  console.log('message', message);
  console.log('buttonText', buttonText);
  console.log('buttonLink', buttonLink);

  const styles = {
    container: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '-100px',
      padding: '35px',
      fontFamily: fontFamilies.regular,
    },
    message: {
      fontSize: '18px',
      fontFamily: fontFamilies.regular,
      color: '#000',
      marginBottom: '20px',
    },
    button: {
      marginTop: '50px',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 14,
      borderRadius: 8,
      borderColor: '#D5D5D5',
      borderWidth: 1,
      fontFamily: fontFamilies.medium,
      color: 'White',
      backgroundColor: '#2A1662',
    },
  };

  return (
    <>
      <div style={styles.container}>
        <h1
          style={styles.message}
          dangerouslySetInnerHTML={{__html: message.replace(/\n/g, '<br />')}}
        />
        <button onClick={() => navigate(buttonLink)} style={styles.button}>
          {buttonText || 'Go'}
        </button>
      </div>
      <BottomNav />
    </>
  );
};

export default DynamicMessagePage;
