import {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';
import MyLikes from './MyLikes';
import MyRewards from './MyRewards';

const SavedPage = () => {
  const location = useLocation();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);
  const [activeTab, setActiveTab] = useState('coupons');

  useEffect(() => {
    const savedTab = localStorage.getItem('activeTab');
    if (savedTab) {
      setActiveTab(savedTab);
    } else if (location.state && location.state.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  const handleSetActiveTab = tab => {
    setActiveTab(tab);
    localStorage.setItem('activeTab', tab);
  };

  const styles = {
    pageContainer: {
      fontFamily: fontFamilies.regular,
      textAlign: 'center',
      color: '#333',
      padding: '20px',
      maxWidth: '400px',
      margin: 'auto',
    },
    navTabs: {
      display: 'flex',
      justifyContent: 'space-around',
      marginBottom: '20px',
      fontFamily: fontFamilies.semiBold,
    },
    navButton: {
      fontFamily: fontFamilies.semiBold,
      background: 'none',
      border: 'none',
      padding: '10px 20px',
      cursor: 'pointer',
      borderBottom: '3px solid transparent', // Default state
    },
    navButtonActive: {
      borderBottom: '3px solid #2A1662', // Active state
    },
    mainContent: {
      margin: '20px 0',
    },
    articlesContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    articleCard: {
      width: '100%',
      marginBottom: '20px',
      padding: '10px',
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer', // Add pointer cursor for clickable cards
    },
    articleImage: {
      width: '100%',
      borderRadius: '10px',
    },
    articleTitle: {
      fontFamily: fontFamilies.semiBold,
      fontSize: '16px',
      marginTop: '10px',
    },
  };

  const MyCoupons = () => {
    return <MyRewards type="coupon" />;
  };

  const MyEarnings = () => {
    return <MyRewards type="earn" />;
  };

  return (
    <>
      <TopNav text={translate(language, 'savedPage.saved')} />
      <div style={styles.pageContainer}>
        <div style={styles.navTabs}>
          <button
            style={
              activeTab === 'coupons'
                ? {...styles.navButton, ...styles.navButtonActive}
                : styles.navButton
            }
            onClick={() => handleSetActiveTab('coupons')}>
            {translate(language, 'savedPage.myCoupons')}
          </button>
          <button
            style={
              activeTab === 'likes'
                ? {...styles.navButton, ...styles.navButtonActive}
                : styles.navButton
            }
            onClick={() => handleSetActiveTab('likes')}>
            {translate(language, 'savedPage.myLikes')}
          </button>
          <button
            style={
              activeTab === 'earnings'
                ? {...styles.navButton, ...styles.navButtonActive}
                : styles.navButton
            }
            onClick={() => handleSetActiveTab('earnings')}>
            {translate(language, 'savedPage.myEarnings')}
          </button>
        </div>
        <div style={styles.mainContent}>
          {activeTab === 'coupons' && <MyCoupons />}
          {activeTab === 'likes' && (
            <MyLikes
              translate={translate}
              language={language}
              styles={styles}
            />
          )}
          {activeTab === 'earnings' && <MyEarnings />}
        </div>
      </div>
      <BottomNav />
    </>
  );
};

export default SavedPage;
