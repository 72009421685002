import {ThemeProvider, createTheme} from '@mui/material/styles';
import {createContext, useReducer, useState} from 'react';
import {Route, Routes} from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import LanguageProvider from './contexts/LanguageProvider';
import Article from './screens/Article';
import DynamicMessagePage from './screens/DynamicMessagePage';
import Explorer from './screens/Explorer';
import RegProduct from './screens/RegProduct';
import RegLogo from './screens/RegLogo';
import ForgotPassword from './screens/ForgotPassword';
import Home from './screens/Home';
import Login from './screens/Login';
import MyRewards from './screens/MyRewards';
import Partner from './screens/Partner';
import Points from './screens/Points';
import Notifications from './screens/Notifications';
import QRScanPage from './screens/QRScanPage';
import Redeem from './screens/Redeem';
import Saved from './screens/Saved';
import Settings from './screens/Settings';
import Signup from './screens/Signup';
import RoleActivate from './screens/RoleActivate';
import TermsPrivacy from './screens/TermsPrivacy';
import UserGuide from './screens/UserGuide';
import UserProfile from './screens/UserProfile';

export const UserContext = createContext();
export const LanguageContext = createContext();

const initialState = {
  // your initial state here
};

const reducer = {
  // your reducer logic here
};

const basicTheme = createTheme(); // A basic theme for testing

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [language, setLanguage] = useState(
    localStorage.getItem('Language') || 'en',
  );

  const switchLanguage = lang => {
    setLanguage(lang);
    localStorage.setItem('Language', lang);
  };

  return (
    <LanguageProvider value={{language, switchLanguage}}>
      <ThemeProvider theme={basicTheme}>
        <UserContext.Provider value={{state, dispatch}}>
          <LanguageContext.Provider value={{language, switchLanguage}}>
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/explorer"
                element={
                  <ProtectedRoute>
                    <Explorer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/register-product"
                element={
                  <ProtectedRoute>
                    <RegProduct />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/register-logo"
                element={
                  <ProtectedRoute>
                    <RegLogo />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/redeem"
                element={
                  <ProtectedRoute>
                    <Redeem />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/myRewards"
                element={
                  <ProtectedRoute>
                    <MyRewards />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/saved"
                element={
                  <ProtectedRoute>
                    <Saved />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user-guide"
                element={
                  <ProtectedRoute>
                    <UserGuide />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/terms-privacy"
                element={
                  <ProtectedRoute>
                    <TermsPrivacy />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <UserProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/points"
                element={
                  <ProtectedRoute>
                    <Points />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/notifications"
                element={
                  <ProtectedRoute>
                    <Notifications />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/article/:id"
                element={
                  <ProtectedRoute>
                    <Article />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/partner/:id"
                element={
                  <ProtectedRoute>
                    <Partner />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/scanQR"
                element={
                  <ProtectedRoute>
                    <QRScanPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/login" element={<Login />} />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/role-activate" element={<RoleActivate />} />
              <Route path="/message" element={<DynamicMessagePage />} />
              {/* other routes */}
            </Routes>
          </LanguageContext.Provider>
        </UserContext.Provider>
      </ThemeProvider>
    </LanguageProvider>
  );
}

export default App;
