import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import { LanguageContext } from '../contexts/LanguageProvider';
import { useFontFamilyByLanguage } from '../hooks/useFontFamilyByLanguage';
import { translate } from '../utils/translate';
import RedeemItemDetail from './RedeemItemDetail';

const RedeemPage = () => {
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  const { fontFamilies } = useFontFamilyByLanguage();

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const [Points, setPoints] = useState(userInfo.points || 0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState({});
  const [vouchers, setVouchers] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [, setLoading] = useState(false);
  const [, setError] = useState(null);

  const styles = {
    redeemContainer: {
      fontFamily: fontFamilies.regular,
      textAlign: 'center',
      color: '#333',
      padding: '20px',
      maxWidth: '400px',
      margin: 'auto',
      backgroundColor: '#f1f6f8', // Adjust as per your design
    },
    pointsBalanceSection: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px',
      backgroundColor: '#fff',
      margin: '10px 0',
      borderRadius: '10px',
      fontFamily: fontFamilies.medium,
      // Add more styling for this section
    },
    voucherList: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)', // Two columns grid
      gap: '10px', // Space between items
      margin: '20px 0',
      fontFamily: fontFamilies.bold,
    },
    voucherItem: {
      backgroundColor: '#ffffff',
      borderRadius: '10px',
      padding: '10px',
      display: 'flex',
      justifyContent: 'center', // Center the voucher image
      alignItems: 'center', // Center vertically
      flexDirection: 'column',
      textAlign: 'center', // Center text
    },
    voucherImage: {
      maxWidth: '100%', // Ensure the image is not wider than the container
      height: '77px', // Maintain aspect ratio
      marginBottom: '10px',
    },
    instructionSection: {
      backgroundColor: '#fff',
      padding: '10px',
      borderRadius: '10px',
      margin: '10px 0',
      fontFamily: fontFamilies.medium,
      textAlign: 'left', // Align text to the left
      // Add more styling for this section
    },
    voucherName: {
      fontFamily: fontFamilies.bold,
      marginBottom: '5px',
    },
    voucherPoints: {
      fontFamily: fontFamilies.light,
    },
    voucherDescription: {
      fontFamily: fontFamilies.regular,
      marginTop: '5px',
    },
    gcxLink: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px',
      backgroundColor: '#fff',
      margin: '10px 0',
      borderRadius: '10px',
      fontFamily: fontFamilies.medium,
    },
    quantityInput: {
      marginTop: '10px',
      width: '60px',
      textAlign: 'center',
    },
    checkoutButton: {
      marginTop: '20px',
      padding: '10px 20px',
      fontFamily: fontFamilies.bold,
      backgroundColor: '#4CAF50',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    },
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.log('No token found');
          return; // Optionally handle the situation by redirecting to login or showing an error
        }
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/auth/getUserInfo`,
          config
        );
        setPoints(response.data?.userInfo?.points || userInfo.points);

        // Update localStorage
        localStorage.setItem('token', response.data.token);
        localStorage.setItem(
          'userInfo',
          JSON.stringify(response.data.userInfo)
        );
      } catch (error) {
        console.error('Failed to fetch user info:', error);
        // Optionally handle errors, like redirecting to login or showing an error message
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const fetchVouchers = async () => {
      setLoading(true);
      try {
        const response = await axios(
          `${process.env.REACT_APP_API_URI}/api/redeem/list`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`, // If authentication is required
            },
          }
        );
        setVouchers(response.data);
        setLoading(false);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          // If unauthorized, redirect to login and clear the token
          localStorage.removeItem('token');
          navigate('/login');
        } else {
          // Handle other errors
          console.error('Error fetching data:', err);
          setError(err.message);
          setLoading(false);
        }
      }
    };

    fetchVouchers();
  }, [navigate]);

  const handleOpen = (voucher) => {
    setSelectedVoucher(voucher);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleCheckout = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}/api/transaction/create-checkout-session`,
        { productId: selectedVoucher._id, quantity },
        config
      );
      const sessionId = response.data.id;
      // Redirect to Stripe Checkout
      window.location.href = `https://checkout.stripe.com/pay/${sessionId}`;
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  // Sort vouchers with "BTO" type first
  const sortedVouchers = [...vouchers].sort((a, b) =>
    a.type === 'BTO' ? -1 : 1
  );

  return (
    <>
      <TopNav
        text={translate(language, 'redeemPage.title')}
        info={translate(language, 'infoDialog.redeemDescription')}
      />
      <div style={styles.redeemContainer}>
        <div style={styles.pointsBalanceSection}>
          <span>{translate(language, 'redeemPage.pointsAvailable')}</span>
          <span>{Points}</span>
        </div>
        <div style={styles.voucherList}>
          {sortedVouchers.map((voucher, index) => (
            <div
              key={index}
              style={styles.voucherItem}
              onClick={() => handleOpen(voucher)}
            >
              <img
                src={voucher.image}
                alt={voucher.title[language]}
                style={styles.voucherImage}
              />
              <span style={styles.voucherName}>{voucher.title[language]}</span>
              <span style={styles.voucherDescription}>
                {voucher.description[language]}
              </span>
              <span style={styles.voucherPoints}>
                {voucher.points} Points
              </span>
            </div>
          ))}
        </div>
        <div style={styles.instructionSection}>
          <p>{translate(language, 'redeemPage.redeemNow')}</p>
          <p>{translate(language, 'redeemPage.redeemInstructions')}</p>
        </div>
      </div>
      <button onClick={handleCheckout} style={styles.checkoutButton}>
        {translate(language, 'redeemPage.checkout')}
      </button>
      <RedeemItemDetail
        isOpen={isOpen}
        selectedVoucher={selectedVoucher}
        handleClose={handleClose}
      />
      <BottomNav />
    </>
  );
};

export default RedeemPage;
