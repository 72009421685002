// utils/translate.js
import translations from '../contexts/translations.json';

export const translate = (language, key) => {
  const keys = key.split('.');
  let translation = translations[language];

  keys.forEach(k => {
    translation = translation[k];
  });

  return translation;
};
