import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import {useContext, useEffect, useRef, useState} from 'react';
import QrReader from 'react-qr-reader';
import {useNavigate} from 'react-router-dom';
import ErrorBoundary from '../components/ErrorBoundary'; // Adjust the import path as needed
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';
import CircularProgress from '@mui/material/CircularProgress'; // Import the CircularProgress component

function QRScanPage() {
  const navigate = useNavigate();
  const {language} = useContext(LanguageContext);
  const [scanResult, setScanResult] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const videoStreamRef = useRef(); // Ref to hold the video stream

  useEffect(() => {
    const processScanResult = async () => {
      if (!scanResult || isProcessing) return;

      setIsProcessing(true);
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          alert(translate(language, 'qrScan.authRequired'));
          navigate('/login');
          return;
        }

        const response = await axios.post(
          `${process.env.REACT_APP_API_URI}/api/redeem/scanQR`,
          {qr: {text: scanResult}},
          {headers: {Authorization: `Bearer ${token}`}},
        );

        navigate('/message', {
          state: {
            message:
              translate(language, 'qrScan.success') + response?.data?.message,
            buttonText: translate(language, 'qrScan.returnHome'),
            buttonLink: '/',
          },
        });
        window.location.reload();
      } catch (error) {
        console.error('Error during QR Scan:', error);
        navigate('/message', {
          state: {
            message: translate(language, 'qrScan.invalidQrCode'),
            buttonText: translate(language, 'qrScan.returnHome'),
            buttonLink: '/',
          },
        });
      }
    };

    processScanResult();
  }, [scanResult, isProcessing, navigate, language]);

  useEffect(() => {
    // Cleanup function to stop the video stream when the component unmounts or navigates away
    return () => {
      if (videoStreamRef.current) {
        const tracks = videoStreamRef.current.getTracks();
        tracks.forEach(track => track.stop()); // Stop each track to release the camera
      }
    };
  }, []);

  const styles = {
    scannerContainer: {
      height: '100vh', // Ensures the container uses the full viewport height
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center', // Centers the camera vertically
      overflow: 'hidden', // Prevents scrollbars
      position: 'relative',
    },
    scanner: {
      height: '100vh', // Ensures the QR reader fills the height
      width: '300%', // Ensures the QR reader fills the width
      position: 'absolute',
      marginTop: -430,
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
      zIndex: 1,
    },
    frame: {
      width: '300px',
      height: '300px',
      border: '2px solid white',
      borderRadius: '10px',
      position: 'relative',
    },
    text: {
      color: 'white',
      marginTop: '20px',
      textAlign: 'center',
    },
    instructionText: {
      color: 'white',
      marginTop: '10px',
      textAlign: 'center',
    },
    errorText: {
      color: 'red',
      marginTop: '10px',
      textAlign: 'center',
    },
    spinnerOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 9999,
    },
  };

  const handleScan = data => {
    if (data && data !== scanResult && !isProcessing) {
      setScanResult(data);
    }
  };

  const handleError = err => {
    console.error('QR Reader Error:', err);
    setErrorMessage(translate(language, 'qrScan.focusOnQrCode'));
  };

  const handleClose = () => {
    // Stop the video stream when closing
    if (videoStreamRef.current) {
      const tracks = videoStreamRef.current.getTracks();
      tracks.forEach(track => track.stop()); // Stop each track to release the camera
    }
    navigate('/');
  };

  return (
    <div>
      {isProcessing && (
        <div style={styles.spinnerOverlay}>
          <CircularProgress color="inherit" size={80} />{' '}
          {/* Increase the size of the spinner */}
        </div>
      )}
      <div style={styles.scannerContainer}>
        <QrReader
          delay={1000}
          onError={handleError}
          onScan={handleScan}
          style={styles.scanner}
        />
        <div style={styles.overlay}>
          <div style={styles.frame}></div>
          <div style={styles.text}>
            {translate(language, 'qrScan.aimAtQrCode')}
          </div>
          <div style={styles.instructionText}>
            {translate(language, 'qrScan.fitQrCodeInBox')}
          </div>
          <div style={styles.instructionText}>
            {translate(language, 'qrScan.additionalMessage')}
          </div>
          {errorMessage && <div style={styles.errorText}>{errorMessage}</div>}
        </div>
        <CloseIcon
          style={{
            color: 'white',
            fontSize: '4rem',
            position: 'absolute',
            top: '10px',
            right: '10px',
            zIndex: 2,
          }}
          onClick={handleClose}
        />
      </div>
    </div>
  );
}

export default function WrappedQRScanPage() {
  return (
    <ErrorBoundary>
      <QRScanPage />
    </ErrorBoundary>
  );
}
