import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';

const PartnerPage = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);
  const [partner, setPartner] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchPartnerDetail = async () => {
      setLoading(true);
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/partner/detail/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setPartner(response.data);
      } catch (err) {
        console.error('Error fetching partner details:', err);
        setError(translate(language, 'partner.error') + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPartnerDetail();
  }, [id, navigate, language]);

  const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      textAlign: 'center',
      color: '#333',
      padding: '20px',
      backgroundColor: '#f1f6f8',
    },
    headerImage: {
      width: 'auto',
      height: '200px',
      objectFit: 'cover',
    },
    name: {
      fontSize: '24px',
      color: '#333',
      fontWeight: 'bold',
      margin: '20px 0',
      fontFamily: fontFamilies.bold,
    },
    description: {
      fontSize: '16px',
      color: '#666',
      margin: '10px 0',
      fontFamily: fontFamilies.semiBold,
    },
    button: {
      backgroundColor: '#2A1662',
      color: '#fff',
      border: 'none',
      padding: '10px 20px',
      borderRadius: '5px',
      margin: '20px 0',
      cursor: 'pointer',
      fontFamily: fontFamilies.regular,
    },
    pointsInfo: {
      backgroundColor: '#F0F0F0',
      padding: '10px 20px',
      borderRadius: '20px',
      margin: '10px 0',
      fontFamily: fontFamilies.semiBoldItalic,
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      backgroundColor: 'transparent',
      border: 'none',
      fontSize: '24px',
      cursor: 'pointer',
    },
  };

  if (loading) {
    return (
      <div>
        <TopNav text={translate(language, 'partner.detail')} />
        <div style={styles.container}>
          <button style={styles.closeButton} onClick={() => navigate('/')}>
            &times;
          </button>
          <Skeleton height={200} />
          <h1 style={styles.name}>
            <Skeleton width={200} />
          </h1>
          <p style={styles.description}>
            <Skeleton count={3} />
          </p>
          <Skeleton height={40} width={150} />
          <div style={styles.pointsInfo}>
            <p>
              <Skeleton width={100} />
            </p>
            <p>
              <Skeleton width={150} />
            </p>
          </div>
          <div style={styles.pointsInfo}>
            <p>
              <Skeleton width={100} />
            </p>
            <p>
              <Skeleton width={150} />
            </p>
          </div>
        </div>
        <BottomNav />
      </div>
    );
  }

  return (
    <div>
      <TopNav text={translate(language, 'partner.detail')} />
      {partner && (
        <div style={styles.container}>
          <button style={styles.closeButton} onClick={() => navigate('/')}>
            &times;
          </button>
          <img src={partner.nameImg} alt="Cover" style={styles.headerImage} />
          <h1 style={styles.name}>{partner.name[language]}</h1>
          <p style={styles.description}>{partner.description[language]}</p>
          <button
            style={styles.button}
            onClick={() => window.open(partner.webLink, '_blank')}>
            {translate(language, 'partner.shopNow')}
          </button>
          <div style={styles.pointsInfo}>
            <p>{translate(language, 'partner.basePoints')}</p>
            <p>
              {translate(language, 'partner.pointsInfo').replace(
                '{points}',
                partner.pointsRatio,
              )}
            </p>
          </div>
          <div style={styles.pointsInfo}>
            <p>{translate(language, 'partner.extraPoints')}</p>
            <p>
              {translate(language, 'partner.pointsInfo').replace(
                '{points}',
                partner.pointsRatio * 3,
              )}
            </p>
          </div>
        </div>
      )}
      <BottomNav />
    </div>
  );
};

export default PartnerPage;
