import {useContext} from 'react';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';

const UserGuide = () => {
  const {language} = useContext(LanguageContext);
  const {fontFamilies} = useFontFamilyByLanguage();

  // Inline CSS styles
  const styles = {
    guideContainer: {
      fontFamily: fontFamilies.regular,
      textAlign: 'left',
      color: '#333',
      padding: '20px',
      maxWidth: '800px',
      margin: 'auto',
      backgroundColor: '#f1f6f8', // Adjust as per your design
    },
    heading: {
      fontFamily: fontFamilies.bold,
      fontSize: '20px',
      marginBottom: '20px',
    },
    section: {
      marginBottom: '20px',
    },
    sectionTitle: {
      fontFamily: fontFamilies.semiBold,
      fontSize: '16px',
      marginBottom: '10px',
    },
    sectionContent: {
      fontFamily: fontFamilies.regular,
      fontSize: '12px',
      lineHeight: '1.6',
    },
    conclusion: {
      fontFamily: fontFamilies.regular,
      fontSize: '12px',
      lineHeight: '1.6',
      marginTop: '20px',
    },
  };

  return (
    <>
      <TopNav text={translate(language, 'userGuide.title')} />
      <div style={styles.guideContainer}>
        <h1 style={styles.heading}>
          {translate(language, 'userGuide.heading')}
        </h1>
        <p>{translate(language, 'userGuide.introduction')}</p>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>
            {translate(language, 'userGuide.section1.title')}
          </h2>
          <p style={styles.sectionContent}>
            {translate(language, 'userGuide.section1.content')}
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>
            {translate(language, 'userGuide.section2.title')}
          </h2>
          <p style={styles.sectionContent}>
            {translate(language, 'userGuide.section2.content')}
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>
            {translate(language, 'userGuide.section3.title')}
          </h2>
          <p style={styles.sectionContent}>
            {translate(language, 'userGuide.section3.content')}
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>
            {translate(language, 'userGuide.section4.title')}
          </h2>
          <p style={styles.sectionContent}>
            {translate(language, 'userGuide.section4.content')}
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>
            {translate(language, 'userGuide.section5.title')}
          </h2>
          <p style={styles.sectionContent}>
            {translate(language, 'userGuide.section5.content')}
          </p>
        </div>

        <p style={styles.conclusion}>
          {translate(language, 'userGuide.conclusion')}
        </p>
      </div>
      <BottomNav />
    </>
  );
};

export default UserGuide;
