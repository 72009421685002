import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';
import QRCode from 'qrcode.react';

const Rewards = ({type}) => {
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);
  const [rewards, setRewards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [itemContent, setItemContent] = useState(null);
  const [qrCode, setQrCode] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    const fetchRewards = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/redeem/myredeems?type=${type}`,
          {
            headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
          },
        );

        const redeemItemResponse = await axios(
          `${process.env.REACT_APP_API_URI}/api/redeem/list`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );

        const myRewards = response.data.map(reward => {
          const redeemItem = redeemItemResponse.data.find(
            item => item._id === reward.redeemItemSnapshot._id,
          );
          const replaceItem = redeemItem
            ? redeemItem
            : reward.redeemItemSnapshot;
          return {
            ...reward,
            redeemItemSnapshot: {
              ...replaceItem,
            },
          };
        });

        console.log(myRewards);

        setRewards(myRewards);
      } catch (error) {
        console.error('Failed to fetch rewards:', error);
        setError(translate(language, 'rewards.error'));
      } finally {
        setLoading(false);
      }
    };

    fetchRewards();
  }, [language, type]);

  const handleCardClick = async id => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URI}/api/redeem/myredeems/${id}`,
        {
          headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        },
      );

      if (response.status === 200 && response.data?.itemContent?.html) {
        setItemContent(response.data.itemContent.html);
        setQrCode(response.data.itemContent.qrcode);
        setDialogOpen(true);
      } else {
        console.error('Failed to fetch item content or item content is null');
      }
    } catch (error) {
      console.error('Failed to fetch item content:', error);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setItemContent(null);
    setQrCode(null);
  };

  const styles = {
    container: {
      fontFamily: fontFamilies.regular,
      textAlign: 'center',
      color: '#333',
      padding: '20px',
      maxWidth: '400px',
      margin: 'auto',
      backgroundColor: '#f1f6f8',
    },
    rewardsList: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '10px',
      marginTop: '20px',
    },
    rewardItem: {
      backgroundColor: '#ffffff',
      borderRadius: '10px',
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    rewardImage: {
      maxWidth: '100%',
      height: '77px',
      marginBottom: '10px',
    },
    rewardText: {
      fontSize: '12px',
      color: '#333',
    },
    actionButton: {
      fontFamily: fontFamilies.semiBold,
      backgroundColor: '#2A1662',
      color: 'white',
      padding: '10px 20px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      marginTop: '20px',
    },
  };

  return (
    <>
      <TopNav text={translate(language, 'rewards.myRewards')} />
      <div style={styles.container}>
        {loading ? (
          <div style={styles.rewardsList}>
            <Skeleton
              variant="rectangular"
              height={120}
              style={{marginBottom: '10px'}}
            />
            <Skeleton
              variant="rectangular"
              height={120}
              style={{marginBottom: '10px'}}
            />
            <Skeleton
              variant="rectangular"
              height={120}
              style={{marginBottom: '10px'}}
            />
            <Skeleton
              variant="rectangular"
              height={120}
              style={{marginBottom: '10px'}}
            />
          </div>
        ) : error ? (
          <p>
            {translate(language, 'rewards.error')} {error}
          </p>
        ) : rewards && rewards.length > 0 ? (
          <div style={styles.rewardsList}>
            {rewards.map((reward, index) => (
              <div
                key={index}
                style={styles.rewardItem}
                onClick={() => handleCardClick(reward.redeemItemSnapshot._id)}>
                {reward.redeemItemSnapshot.image && (
                  <img
                    src={reward.redeemItemSnapshot.image}
                    alt={reward.redeemItemSnapshot.name[language]}
                    style={styles.rewardImage}
                  />
                )}
                <span style={styles.rewardText}>
                  {reward.redeemItemSnapshot.name[language]}
                </span>
                <span style={styles.rewardText}>
                  {reward.quantity} {translate(language, 'rewards.quantity')}
                </span>
                <span style={styles.rewardText}>
                  {new Date(reward.created).toLocaleString('en-US', {
                    timeZone: 'Asia/Hong_Kong',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </span>
                <span style={styles.rewardText}>
                  {translate(language, 'rewards.status')}: {reward.status}
                </span>
              </div>
            ))}
          </div>
        ) : (
          <div style={styles.container}>
            <p>{translate(language, 'rewards.noRewardsFound')}</p>
            <button
              style={styles.actionButton}
              onClick={() =>
                navigate('/explorer', {state: {showBackIcon: false}})
              }>
              {translate(language, 'rewards.viewCoupons')}
            </button>
          </div>
        )}
      </div>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{translate(language, 'rewards.itemContent')}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{__html: itemContent}} />
          {qrCode && (
            <QRCode
              value={qrCode}
              size={256}
              style={{display: 'block', margin: '20px auto'}}
            />
          )}
          <Button onClick={handleCloseDialog}>
            {translate(language, 'close')}
          </Button>
        </DialogContent>
      </Dialog>
      <BottomNav />
    </>
  );
};

export default Rewards;
