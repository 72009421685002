export const isAuthenticated = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return false;
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URI}/api/auth/checkAuthToken`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    // Assuming the API returns a successful response when the token is valid
    return response.status;
  } catch (error) {
    console.error('Error during auth token validation:', error);
    return false;
  }
};
