import {useNavigate, useLocation} from 'react-router-dom';
import {useContext, useState} from 'react';
import axios from 'axios';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import TopNav from '../Navigation/TopNav';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {LanguageContext} from '../contexts/LanguageProvider';

const RoleActivate = () => {
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);
  const location = useLocation();
  const {firstName, lastName, email, password, referralCode} =
    location.state || {};

  const [selectedRoles, setSelectedRoles] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [position, setPosition] = useState('');
  const [document, setDocument] = useState(null);
  const [resume, setResume] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [currentDescription, setCurrentDescription] = useState('');

  const handleRoleSelection = role => {
    setSelectedRoles(prevRoles =>
      prevRoles.includes(role)
        ? prevRoles.filter(r => r !== role)
        : [...prevRoles, role],
    );
  };

  const handleFileChange = e => {
    setDocument(e.target.files[0]);
  };

  const handleResumeChange = e => {
    setResume(e.target.files[0]);
  };

  const handleSubmit = async () => {
    setError('');
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('userEmail', email);
      formData.append('userPassword', password);
      formData.append('referralCode', referralCode || undefined);
      formData.append('roles', selectedRoles);

      if (selectedRoles.includes('Product Provider')) {
        formData.append('companyName', companyName);
        formData.append('companyAddress', companyAddress);
        formData.append('position', position);
        if (document) {
          formData.append('document', document);
        }
      }

      if (selectedRoles.includes('Entrepreneurs')) {
        if (resume) {
          formData.append('resume', resume);
        }
      }

      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/createAccount`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      navigate('/message', {
        state: {
          message: 'Signup successful!',
          buttonText: 'Return to Login',
          buttonLink: '/login',
        },
      });
    } catch (error) {
      console.error('Error signing up:', error);
      setError('Signup failed. Please try again.');
      setLoading(false);
    }
  };

  const handleClickOpen = description => {
    setCurrentDescription(description);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      textAlign: 'center',
      fontFamily: 'Arial, sans-serif',
      height: '80vh',
    },
    roleCard: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '20px',
      margin: '10px 0',
      border: '1px solid #ccc',
      borderRadius: '10px',
      cursor: 'pointer',
      width: '80%',
    },
    roleSelected: {
      border: '2px solid #2A1662',
    },
    submitButton: {
      width: '100%',
      height: '40px',
      marginTop: '20px',
      padding: '0px 16px',
      border: 'none',
      borderRadius: '20px',
      backgroundColor: '#2A1662',
      color: '#ffffff',
      cursor: 'pointer',
    },
    input: {
      width: '100%',
      height: '40px',
      margin: '10px 0',
      padding: '0px 16px',
      border: '1px solid #ccc',
      boxSizing: 'border-box',
      borderRadius: '20px',
      boxShadow: 'none',
      backgroundColor: '#ffffff',
      fontFamily: fontFamilies.regular,
    },
    fileInput: {
      display: 'none',
    },
    fileInputLabel: {
      display: 'block',
      padding: '10px',
      border: '1px dashed #ccc',
      borderRadius: '10px',
      cursor: 'pointer',
    },
  };

  const roles = [
    {
      name: 'Logo Creator',
      description: `Creators focused on tokenizing and selling digital assets as NFTs. 

    Adhere to platform guidelines for creating and selling NFTs, provide accurate descriptions, and follow best practices for NFT transactions.
    
    1. Acceptance of Terms:
    By accessing and using this platform, you agree to comply with these Terms and Conditions.
    
    2. User Roles:
    This platform caters to different user roles, including Non-Business (Buyers), Entrepreneurs and Service Providers, Service Providers (Focus on Services), and NFT Creators.
    
    3. Account Registration:
    Users are required to create an account to access certain features. You agree to provide accurate and complete information during the registration process.
    
    4. Buying and Selling:
    Non-Business users can browse, select, and purchase products or services.
    Entrepreneurs and Service Providers can list and sell products or services, set pricing, and engage in negotiations as applicable.
    Service Providers (Focus on Services) offer service-based offerings on the platform.
    NFT Creators tokenize and sell their digital assets through the platform.
    
    5. Payment and Transactions:
    Secure payment options are provided for transactions. Buyers and sellers are responsible for the accuracy of transaction details.
    
    6. Communication:
    Users can communicate through the platform's messaging system. Respectful and professional communication is expected.
    
    7. Content Upload:
    Entrepreneurs, Service Providers, and NFT Creators can upload content related to their offerings. Users are responsible for the legality and accuracy of the content.
    
    8. Intellectual Property:
    Users retain intellectual property rights to their content. By uploading, you grant the platform a license to display, distribute, and promote the content.
    
    9. Compliance:
    Users agree to comply with all applicable laws and regulations, including those related to intellectual property, consumer protection, and NFT transactions.
    
    10. Community Guidelines:
    Users are expected to adhere to community guidelines promoting a positive and respectful environment.
    
    11. Termination:
    The platform reserves the right to terminate or suspend accounts for violations of these Terms and Conditions.
    
    12. Changes to Terms:
    The platform may update these Terms and Conditions. Users are encouraged to review them regularly.
    
    13. Disclaimer of Liability:
    The platform is not responsible for the quality, safety, or legality of products, services, or transactions between users.`,
    },
    {
      name: 'Product Provider',
      description: `Service-based providers offering their expertise on the platform.

    Clearly define services offered, manage appointments (if applicable), and maintain a high level of professionalism in interactions with clients.
    
    1. Acceptance of Terms:
    By accessing and using this platform, you agree to comply with these Terms and Conditions.
    
    2. User Roles:
    This platform caters to different user roles, including Non-Business (Buyers), Entrepreneurs and Service Providers, Service Providers (Focus on Services), and NFT Creators.
    
    3. Account Registration:
    Users are required to create an account to access certain features. You agree to provide accurate and complete information during the registration process.
    
    4. Buying and Selling:
    Non-Business users can browse, select, and purchase products or services.
    Entrepreneurs and Service Providers can list and sell products or services, set pricing, and engage in negotiations as applicable.
    Service Providers (Focus on Services) offer service-based offerings on the platform.
    NFT Creators tokenize and sell their digital assets through the platform.
    
    5. Payment and Transactions:
    Secure payment options are provided for transactions. Buyers and sellers are responsible for the accuracy of transaction details.
    
    6. Communication:
    Users can communicate through the platform's messaging system. Respectful and professional communication is expected.
    
    7. Content Upload:
    Entrepreneurs, Service Providers, and NFT Creators can upload content related to their offerings. Users are responsible for the legality and accuracy of the content.
    
    8. Intellectual Property:
    Users retain intellectual property rights to their content. By uploading, you grant the platform a license to display, distribute, and promote the content.
    
    9. Compliance:
    Users agree to comply with all applicable laws and regulations, including those related to intellectual property, consumer protection, and NFT transactions.
    
    10. Community Guidelines:
    Users are expected to adhere to community guidelines promoting a positive and respectful environment.
    
    11. Termination:
    The platform reserves the right to terminate or suspend accounts for violations of these Terms and Conditions.
    
    12. Changes to Terms:
    The platform may update these Terms and Conditions. Users are encouraged to review them regularly.
    
    13. Disclaimer of Liability:
    The platform is not responsible for the quality, safety, or legality of products, services, or transactions between users.`,
    },
    {
      name: 'Entrepreneurs',
      description: `Sellers who can list and sell products or services, set pricing, and engage in negotiations.

    Provide accurate product or service information, fulfill orders promptly, and maintain professional communication with buyers.
    
    1. Acceptance of Terms:
    By accessing and using this platform, you agree to comply with these Terms and Conditions.
    
    2. User Roles:
    This platform caters to different user roles, including Non-Business (Buyers), Entrepreneurs and Service Providers, Service Providers (Focus on Services), and NFT Creators.
    
    3. Account Registration:
    Users are required to create an account to access certain features. You agree to provide accurate and complete information during the registration process.
    
    4. Buying and Selling:
    Non-Business users can browse, select, and purchase products or services.
    Entrepreneurs and Service Providers can list and sell products or services, set pricing, and engage in negotiations as applicable.
    Service Providers (Focus on Services) offer service-based offerings on the platform.
    NFT Creators tokenize and sell their digital assets through the platform.
    
    5. Payment and Transactions:
    Secure payment options are provided for transactions. Buyers and sellers are responsible for the accuracy of transaction details.
    
    6. Communication:
    Users can communicate through the platform's messaging system. Respectful and professional communication is expected.
    
    7. Content Upload:
    Entrepreneurs, Service Providers, and NFT Creators can upload content related to their offerings. Users are responsible for the legality and accuracy of the content.
    
    8. Intellectual Property:
    Users retain intellectual property rights to their content. By uploading, you grant the platform a license to display, distribute, and promote the content.
    
    9. Compliance:
    Users agree to comply with all applicable laws and regulations, including those related to intellectual property, consumer protection, and NFT transactions.
    
    10. Community Guidelines:
    Users are expected to adhere to community guidelines promoting a positive and respectful environment.
    
    11. Termination:
    The platform reserves the right to terminate or suspend accounts for violations of these Terms and Conditions.
    
    12. Changes to Terms:
    The platform may update these Terms and Conditions. Users are encouraged to review them regularly.
    
    13. Disclaimer of Liability:
    The platform is not responsible for the quality, safety, or legality of products, services, or transactions between users.`,
    },
    {name: 'None', description: 'I am here to observe'},
  ];

  return (
    <>
      <TopNav text={'Role Select'} />
      <div style={styles.container}>
        <h1>Role Activate</h1>
        <p>
          Allows multiple selections and can be edited and created on the STP
          platform.
        </p>
        {roles.map(role => (
          <div
            key={role.name}
            style={{
              ...styles.roleCard,
              ...(selectedRoles.includes(role.name) ? styles.roleSelected : {}),
            }}
            onClick={() => handleRoleSelection(role.name)}>
            <span>{role.name}</span>
            <IconButton
              style={styles.infoButton}
              onClick={e => {
                e.stopPropagation(); // Prevent the click event from bubbling to the card's onClick
                handleClickOpen(role.description);
              }}>
              <InfoIcon />
            </IconButton>
          </div>
        ))}
        {selectedRoles.includes('Product Provider') && (
          <>
            <input
              style={styles.input}
              type="text"
              placeholder="Enter your company name"
              value={companyName}
              onChange={e => setCompanyName(e.target.value)}
            />
            <input
              style={styles.input}
              type="text"
              placeholder="Enter your company address"
              value={companyAddress}
              onChange={e => setCompanyAddress(e.target.value)}
            />
            <input
              style={styles.input}
              type="text"
              placeholder="Enter your position"
              value={position}
              onChange={e => setPosition(e.target.value)}
            />
            <label style={styles.fileInputLabel}>
              <input
                style={styles.fileInput}
                type="file"
                accept="image/jpeg, image/png, application/pdf"
                onChange={handleFileChange}
              />
              Upload Company BR (jpeg, png, pdf)
            </label>
          </>
        )}
        {selectedRoles.includes('Entrepreneurs') && (
          <>
            <label style={styles.fileInputLabel}>
              <input
                style={styles.fileInput}
                type="file"
                accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={handleResumeChange}
              />
              Upload Resume (PDF, DOC, DOCX)
            </label>
          </>
        )}
        {error && <div style={{color: 'red'}}>{error}</div>}
        {loading ? (
          <CircularProgress />
        ) : (
          <button style={styles.submitButton} onClick={handleSubmit}>
            Submit
          </button>
        )}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Role Description</DialogTitle>
          <DialogContent>
            <DialogContentText>{currentDescription}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default RoleActivate;
