import Avatar from '@mui/material/Avatar';
import {useContext, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';
import axios from 'axios';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';

const UserProfile = () => {
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [Name] = useState(userInfo.firstName + ' ' + userInfo.lastName || '');
  const [Role] = useState(userInfo.role || '');
  const [Email] = useState(userInfo.email || '');
  const [Points, setPoints] = useState(userInfo.points || 0);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.log('No token found');
          return; // Optionally handle the situation by redirecting to login or showing an error
        }
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/auth/getUserInfo`,
          config,
        );
        setPoints(response.data.userInfo.points);

        // Update localStorage
        localStorage.setItem('token', response.data.token);
        localStorage.setItem(
          'userInfo',
          JSON.stringify(response.data.userInfo),
        );
      } catch (error) {
        //console.error('Failed to fetch user info:', error);
        // Optionally handle errors, like redirecting to login or showing an error message
      }
    };

    fetchUserInfo();
  }, []);

  const styles = {
    profileContainer: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#ffffff',
      padding: '10px 20px',
      borderRadius: '20px',
      fontFamily: fontFamilies.regular,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      margin: '25px',
      marginBottom: '50px',
    },
    pointsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#ffffff',
      padding: '10px 20px',
      borderRadius: '20px',
      fontSize: '12px',
      fontFamily: fontFamilies.regular,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      margin: '25px',
      marginBottom: '50px',
    },
    pointsLabel: {
      fontSize: '12px',
      fontFamily: fontFamilies.regular,
    },
    points: {
      fontSize: '18px',
      fontFamily: fontFamilies.semiBold,
      display: 'flex',
      alignItems: 'center',
    },
    coinIcon: {
      height: '25px',
      width: '25px',
      marginRight: '5px',
    },
    avatarContainer: {
      marginRight: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    nameRoleEmailContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      lineHeight: '1.2',
    },
    name: {
      fontSize: '18px',
      fontFamily: fontFamilies.semiBold,
      margin: '0 0 5px 0',
      color: '#000',
    },
    role: {
      fontSize: '12px',
      fontFamily: fontFamilies.regular,
      margin: '0 0 5px 0',
      color: '#666',
    },
    email: {
      fontSize: '12px',
      fontFamily: fontFamilies.regular,
      margin: '0',
    },
    settingContainer: {
      marginLeft: '25px',
      marginRight: '25px',
    },
    settingItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '15px',
    },
    settingIcon: {
      marginRight: '15px',
    },
    settingText: {
      flexGrow: 1,
      textDecoration: 'none',
      color: 'black',
      fontSize: '16px',
      fontFamily: fontFamilies.regular,
      marginRight: '15px',
    },
    settingArrow: {},
    link: {
      textDecoration: 'none',
      color: 'inherit',
    },
    logoutButton: {
      marginTop: '50px',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 14,
      borderRadius: 8,
      borderColor: '#D5D5D5',
      borderWidth: 1,
      fontFamily: fontFamilies.medium,
      fontSize: '16px',
      color: '#DB5948',
    },
  };

  const RightArrowIcon = () => (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path
        d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z"
        fill="currentColor"
      />
    </svg>
  );

  const PointsRightArrowIcon = () => (
    <svg height="15" width="15" viewBox="0 0 20 20">
      <path
        d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z"
        fill="currentColor"
      />
    </svg>
  );

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleNavigateToSettings = () => {
    navigate('/settings', {state: {showBackIcon: true}});
  };

  const handleNavigateToPoints = () => {
    navigate('/points', {state: {showBackIcon: true}});
  };

  const handleNavigateToUserGuide = () => {
    navigate('/user-guide', {state: {showBackIcon: true}});
  };

  const handleNavigateToTermsPrivacy = () => {
    navigate('/terms-privacy', {state: {showBackIcon: true}});
  };

  return (
    <>
      <TopNav text={translate(language, 'userProfile.myProfile')} />
      <div style={styles.profileContainer}>
        <div style={styles.avatarContainer}>
          <Avatar
            alt={userInfo.firstName}
            src="/assets/icons/default_avatar.png" // Replace this with the path to your generic user icon
            sx={{width: 60, height: 60, boxShadow: 3}} // Adding shadow and adjusting size
          />
        </div>
        <div style={styles.nameRoleEmailContainer}>
          <h2 style={styles.name}>{Name}</h2>
          <p style={styles.role}>{Role}</p>
          <p style={styles.email}>{Email}</p>
        </div>
      </div>

      <div onClick={handleNavigateToPoints} style={styles.pointsContainer}>
        <div style={styles.pointsLabel}>
          {translate(language, 'userProfile.points')}
          <PointsRightArrowIcon />
        </div>
        <div style={styles.points}>
          <ReactSVG src="/assets/icons/coins.svg" style={styles.coinIcon} />
          {Points}
        </div>
      </div>

      <div style={styles.settingContainer}>
        <div style={styles.settingItem}>
          <ReactSVG
            src="/assets/icons/matching.svg"
            style={styles.settingIcon}
          />
          <span style={styles.settingText}>Matching Platform (soon...)</span>
          <RightArrowIcon style={styles.settingArrow} />
        </div>

        <div style={styles.settingItem} onClick={handleNavigateToSettings}>
          <ReactSVG
            src="/assets/icons/settings.svg"
            style={styles.settingIcon}
          />
          <span style={styles.settingText}>
            {translate(language, 'userProfile.settings')}
          </span>
          <RightArrowIcon style={styles.settingArrow} />
        </div>

        <div style={styles.settingItem} onClick={handleNavigateToUserGuide}>
          <ReactSVG
            src="/assets/icons/user_guide.svg"
            style={styles.settingIcon}
          />
          <span style={styles.settingText}>
            {translate(language, 'userProfile.userGuide')}
          </span>
          <RightArrowIcon style={styles.settingArrow} />
        </div>
        <div style={styles.settingItem} onClick={handleNavigateToTermsPrivacy}>
          <ReactSVG
            src="/assets/icons/info_profile.svg"
            style={styles.settingIcon}
          />
          <span style={styles.settingText}>
            {translate(language, 'userProfile.termsPrivacy')}
          </span>
          <RightArrowIcon style={styles.settingArrow} />
        </div>
        <button style={styles.logoutButton} onClick={handleLogout}>
          {translate(language, 'userProfile.logout')}
        </button>
      </div>
      <BottomNav />
    </>
  );
};

export default UserProfile;
