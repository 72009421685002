import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';

const Points = () => {
  const navigate = useNavigate();
  const {language} = useContext(LanguageContext);
  const {fontFamilies} = useFontFamilyByLanguage();

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [Points] = useState(userInfo.points || 0);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      const token = localStorage.getItem('token');

      if (!token) {
        console.error('No token found, user must login first.');
        setError(translate(language, 'points.authRequired'));
        navigate('/login');
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/transaction/listMyTransactions`,
          config,
        );
        setTransactions(response.data);
      } catch (error) {
        console.error('Failed to fetch transactions:', error);
        setError(translate(language, 'points.fetchError'));
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [navigate, language]);

  const styles = {
    topContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      width: '100%',
      backgroundColor: '#2A1662',
      marginTop: '-20px',
      paddingTop: '20px',
      borderRadius: '20px',
      zIndex: 1000,
    },
    topContainerItem: {
      padding: '20px',
      width: '40%',
      marginBottom: '-20px',
      backgroundColor: '#FFFFFF',
      borderRadius: '20px',
      fontSize: '12px',
      fontFamily: fontFamilies.light,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    points: {
      fontSize: '18px',
      fontFamily: fontFamilies.semiBold,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    pointsLabel: {
      fontSize: '12px',
      fontFamily: fontFamilies.regular,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    coinIcon: {
      height: '25px',
      width: '25px',
      marginRight: '5px',
    },
    container: {
      marginTop: '120px',
    },
    itemRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    itemContainer: {
      margin: '20px',
      padding: '20px',
      backgroundColor: '#FFFFFF',
      borderRadius: '20px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    itemTitle: {
      fontSize: '12px',
      fontFamily: fontFamilies.semiBold,
    },
    itemPoints: {
      fontSize: '12px',
      fontFamily: fontFamilies.semiBold,
    },
    itemDatetime: {
      fontSize: '12px',
      fontFamily: fontFamilies.light,
    },
    itemDescription: {
      fontSize: '12px',
      fontFamily: fontFamilies.regular,
    },
  };

  return (
    <>
      <TopNav text={translate(language, 'points.myPoints')} />
      <div style={styles.topContainer}>
        <div style={styles.topContainerItem}>
          {loading ? (
            <>
              <Skeleton variant="text" width={100} />
              <Skeleton variant="text" width={50} />
            </>
          ) : (
            <>
              <div style={styles.pointsLabel}>
                {translate(language, 'points.pointsBalance')}
              </div>
              <div style={styles.points}>
                <ReactSVG
                  src="/assets/icons/coins.svg"
                  style={styles.coinIcon}
                />
                {Points}
              </div>
            </>
          )}
        </div>
      </div>
      <div style={styles.container}>
        {loading ? (
          <>
            <Skeleton
              variant="rectangular"
              height={80}
              style={{marginBottom: '20px'}}
            />
            <Skeleton
              variant="rectangular"
              height={80}
              style={{marginBottom: '20px'}}
            />
            <Skeleton
              variant="rectangular"
              height={80}
              style={{marginBottom: '20px'}}
            />
          </>
        ) : error ? (
          <p>{error}</p>
        ) : transactions.length > 0 ? (
          transactions
            .filter(
              transaction =>
                transaction.itemSnapshot?.name?.[language] &&
                transaction.itemSnapshot?.description?.[language],
            )
            .map(transaction => (
              <div style={styles.itemContainer} key={transaction.id}>
                <div style={styles.itemRow}>
                  <div style={styles.itemTitle}>
                    {transaction.itemSnapshot.name[language]}
                  </div>
                  <div style={styles.itemPoints}>
                    {transaction.points} {translate(language, 'points.points')}
                  </div>
                </div>
                <div style={styles.itemDatetime}>
                  {new Date(transaction.created).toLocaleString()}
                </div>
                <div style={styles.itemDescription}>
                  {transaction.itemSnapshot.description[language]}
                </div>
              </div>
            ))
        ) : (
          <p>{translate(language, 'points.noTransactions')}</p>
        )}
      </div>
      <BottomNav />
    </>
  );
};

export default Points;
