import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';

const Notifications = () => {
  const navigate = useNavigate();
  const {language} = useContext(LanguageContext);
  const {fontFamilies} = useFontFamilyByLanguage();

  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      const token = localStorage.getItem('token');

      if (!token) {
        console.error('No token found, user must login first.');
        setError(translate(language, 'notifications.authRequired'));
        navigate('/login');
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/notification/notifications`,
          config,
        );
        setNotifications(response.data.data);
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
        setError(translate(language, 'notifications.fetchError'));
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [navigate, language]);

  const styles = {
    container: {
      marginTop: '120px',
    },
    itemRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    itemContainer: {
      margin: '20px',
      padding: '20px',
      backgroundColor: '#FFFFFF',
      borderRadius: '20px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    itemTitle: {
      fontSize: '12px',
      fontFamily: fontFamilies.bold,
    },
    redDot: {
      height: '8px',
      width: '8px',
      backgroundColor: 'red',
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: '8px',
    },
    itemDatetime: {
      fontSize: '12px',
      fontFamily: fontFamilies.light,
      marginBottom: '10px',
    },
    itemDescription: {
      fontSize: '12px',
      fontFamily: fontFamilies.regular,
    },
  };

  const getNotificationTitle = notification => {
    if (language === 'en') return notification.title.en;
    if (language === 'zh') return notification.title.zh;
    if (language === 'zhhk') return notification.title.zhhk;
    return notification.title.en; // Default to English if language not found
  };

  const getNotificationBody = notification => {
    if (language === 'en') return notification.body.en;
    if (language === 'zh') return notification.body.zh;
    if (language === 'zhhk') return notification.body.zhhk;
    return notification.body.en; // Default to English if language not found
  };

  return (
    <>
      <TopNav text={translate(language, 'notifications.myNotifications')} />
      <div style={styles.container}>
        {loading ? (
          <>
            <Skeleton
              variant="rectangular"
              height={80}
              style={{marginBottom: '20px'}}
            />
            <Skeleton
              variant="rectangular"
              height={80}
              style={{marginBottom: '20px'}}
            />
            <Skeleton
              variant="rectangular"
              height={80}
              style={{marginBottom: '20px'}}
            />
          </>
        ) : error ? (
          <p>{error}</p>
        ) : notifications.length > 0 ? (
          notifications.map(notification => (
            <div style={styles.itemContainer} key={notification._id}>
              <div style={styles.itemRow}>
                <div style={styles.itemTitle}>
                  {!notification.read && <span style={styles.redDot}></span>}
                  {getNotificationTitle(notification)}
                </div>
              </div>
              <div style={styles.itemDatetime}>
                {new Date(notification.created).toLocaleString('en-US', {
                  month: 'short',
                  day: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </div>
              <div style={styles.itemDescription}>
                {getNotificationBody(notification)}
              </div>
            </div>
          ))
        ) : (
          <div style={styles.itemContainer}>
            <p>{translate(language, 'notifications.noNotifications')}</p>
          </div>
        )}
      </div>
      <BottomNav />
    </>
  );
};

export default Notifications;
