import CircularProgress from '@mui/material/CircularProgress';
import {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';

const Signup = () => {
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [referralCode, setReferralCode] = useState(''); // New state for referral code

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      textAlign: 'center',
      fontFamily: fontFamilies.regular,
      height: '80vh',
    },
    ImageContainer: {
      width: '100%',
      height: '190px',
      backgroundImage: 'url(./image.jpeg)',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    Text: {
      color: '#030303',
      fontFamily: fontFamilies.bold,
      margin: '20px 0',
    },
    SubText: {
      color: '#030303',
      fontFamily: fontFamilies.semiBold,
      margin: '10px 0',
    },
    UserInput: {
      width: '100%',
      height: '40px',
      margin: '10px 0',
      padding: '0px 16px',
      border: '1px solid #ccc',
      boxSizing: 'border-box',
      borderRadius: '20px',
      boxShadow: 'none',
      backgroundColor: '#ffffff',
      fontFamily: fontFamilies.regular,
    },
    SignupLabel: {
      width: '100%',
      height: '40px',
      marginTop: '20px',
      padding: '0px 16px',
      border: 'none',
      borderRadius: '20px',
      backgroundColor: '#2A1662',
      color: '#ffffff',
      fontFamily: fontFamilies.semiBold,
      cursor: 'pointer',
    },
  };

  const validateEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = password => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChars = /\W/.test(password);

    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasSpecialChars
    );
  };

  const handleSignup = async () => {
    setError('');
    setLoading(true);

    if (!firstName || !lastName) {
      setError(translate(language, 'signup.firstNameRequired'));
      setLoading(false);
      return;
    }

    if (!validateEmail(email)) {
      setError(translate(language, 'signup.invalidEmail'));
      setLoading(false);
      return;
    }

    if (!validatePassword(password)) {
      setError(translate(language, 'signup.weakPassword'));
      setLoading(false);
      return;
    }

    if (password !== retypePassword) {
      setError(translate(language, 'signup.passwordMismatch'));
      setLoading(false);
      return;
    }

    navigate('/role-activate', {
      state: {
        showBackIcon: true,
        firstName,
        lastName,
        email,
        password,
        referralCode,
      },
    });
  };

  const formatName = name => {
    return name
      .trim()
      .toLowerCase()
      .replace(/\b\w/g, firstLetter => firstLetter.toUpperCase());
  };

  return (
    <>
      <TopNav text={translate(language, 'signup.pageTitle')} />
      <div style={styles.container}>
        <div style={styles.Text}>
          {translate(language, 'signup.headerText')}
        </div>
        {error && <div style={{color: 'red'}}>{error}</div>}
        <input
          style={styles.UserInput}
          type="text"
          placeholder={translate(language, 'signup.firstName') + ' *'}
          value={firstName}
          onChange={e => setFirstName(formatName(e.target.value))}
        />
        <input
          style={styles.UserInput}
          type="text"
          placeholder={translate(language, 'signup.lastName') + ' *'}
          value={lastName}
          onChange={e => setLastName(formatName(e.target.value))}
        />
        <input
          style={styles.UserInput}
          type="email"
          placeholder={translate(language, 'signup.email') + ' *'}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <input
          style={styles.UserInput}
          type="password"
          placeholder={translate(language, 'signup.password') + ' *'}
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <input
          style={styles.UserInput}
          type="password"
          placeholder={translate(language, 'signup.retypePassword') + ' *'}
          value={retypePassword}
          onChange={e => setRetypePassword(e.target.value)}
        />
        <input // New input for referral code
          style={styles.UserInput}
          type="text"
          placeholder={translate(language, 'signup.referralCode')}
          value={referralCode}
          onChange={e => setReferralCode(e.target.value)}
        />
        {loading ? (
          <CircularProgress />
        ) : (
          <button style={styles.SignupLabel} onClick={handleSignup}>
            {translate(language, 'signup.next')}
          </button>
        )}
      </div>
    </>
  );
};

export default Signup;
