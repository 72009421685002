import axios from 'axios';
import {useContext, useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {useNavigate} from 'react-router-dom';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';

const Explorer = () => {
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const [userType, setUserType] = useState(
    JSON.parse(localStorage.getItem('userInfo')).type || {},
  );

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.log('No token found');
          return; // Optionally handle the situation by redirecting to login or showing an error
        }
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/auth/getUserInfo`,
          config,
        );
        setUserType(response.data.userInfo.type);

        // Update localStorage
        localStorage.setItem('token', response.data.token);
        localStorage.setItem(
          'userInfo',
          JSON.stringify(response.data.userInfo),
        );
      } catch (error) {
        console.error('Failed to fetch user info:', error);
        // Optionally handle errors, like redirecting to login or showing an error message
      }
    };

    fetchUserInfo();
  }, []);

  // Define your styles here
  const styles = {
    explorerContainer: {
      fontFamily: fontFamilies.regular,
      textAlign: 'center',
      color: '#333',
      padding: '20px',
      maxWidth: '400px',
      margin: 'auto',
      backgroundColor: '#f1f6f8', // Adjust as per your design
    },
    header: {
      marginBottom: '20px',
      fontFamily: fontFamilies.semiBold,
    },
    categoryContainer: {
      gap: '10px', // Space between items
      marginBottom: '20px',
    },
    categoryItem: {
      backgroundColor: '#ffffff',
      padding: '10px',
      borderRadius: '10px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    categoryImage: {
      width: '50px',
      height: '50px',
      marginBottom: '10px',
    },
    categoryText: {
      fontSize: '12px',
      color: '#333',
    },
    footerText: {
      marginTop: '20px',
      fontSize: '14px',
      fontFamily: fontFamilies.light,
    },
    registerButton: {
      fontFamily: fontFamilies.semiBold,
      backgroundColor: '#2A1662',
      color: 'white',
      padding: '10px 20px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      marginTop: '20px',
    },
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/partner/list`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setCategories(response.data); // Assuming the API returns an array of categories
        setLoading(false);
      } catch (error) {
        console.error('Error fetching categories:', error);
        navigate('/login');
      }
    };

    fetchCategories();
  }, [navigate]);

  return (
    <>
      <TopNav text={translate(language, 'explorer.title')} />
      <div style={styles.explorerContainer}>
        {userType === 'Product Provider' && (
          <>
            <div style={styles.header}>
              <h2>{translate(language, 'explorer.headerTitle')}</h2>
              <p>{translate(language, 'explorer.headerDescription')}</p>
            </div>
            <div style={styles.categoryContainer}>
              <button
                style={styles.registerButton}
                onClick={() =>
                  navigate('/register-product', {state: {showBackIcon: true}})
                }>
                Register Product
              </button>
            </div>
          </>
        )}
        {userType === 'Logo Creator' && (
          <>
            <div style={styles.header}>
              <h2>{translate(language, 'explorer.NFTTitle')}</h2>
              <p>{translate(language, 'explorer.NFTDescription')}</p>
            </div>
            <div style={styles.categoryContainer}>
              <button
                style={styles.registerButton}
                onClick={() =>
                  navigate('/register-logo', {state: {showBackIcon: true}})
                }>
                Register Logo
              </button>
            </div>
          </>
        )}
        {userType === 'Entrepreneurs' && (
          <>
            <div style={styles.header}>
              <h2>{translate(language, 'explorer.entTitle')}</h2>
              <p>{translate(language, 'explorer.entDescription')}</p>
            </div>
            <div style={styles.categoryContainer}>
              {loading ? (
                <>
                  <div style={styles.categoryItem}>
                    <Skeleton circle height={50} width={50} />
                    <Skeleton
                      height={20}
                      width={80}
                      style={{marginTop: '10px'}}
                    />
                  </div>
                  <div style={styles.categoryItem}>
                    <Skeleton circle height={50} width={50} />
                    <Skeleton
                      height={20}
                      width={80}
                      style={{marginTop: '10px'}}
                    />
                  </div>
                  <div style={styles.categoryItem}>
                    <Skeleton circle height={50} width={50} />
                    <Skeleton
                      height={20}
                      width={80}
                      style={{marginTop: '10px'}}
                    />
                  </div>
                  <div style={styles.categoryItem}>
                    <Skeleton circle height={50} width={50} />
                    <Skeleton
                      height={20}
                      width={80}
                      style={{marginTop: '10px'}}
                    />
                  </div>
                </>
              ) : (
                categories.map(category => (
                  <div
                    key={category._id}
                    style={styles.categoryItem}
                    onClick={() =>
                      navigate(`/partner/${category._id}`, {
                        state: {showBackIcon: true},
                      })
                    }>
                    <img
                      src={category.nameImg}
                      alt={category.name[language]}
                      style={styles.categoryImage}
                    />
                    <span style={styles.categoryText}>
                      {category.name[language]}
                    </span>
                  </div>
                ))
              )}
            </div>
          </>
        )}
      </div>
      <BottomNav />
    </>
  );
};

export default Explorer;
