import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

const RedeemItemDetail = ({isOpen, selectedVoucher, handleClose}) => {
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmScroll, setConfirmScroll] = useState('paper');

  const [walletAddress, setWalletAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(100);
  const token = localStorage.getItem('token');

  const styles = {
    modalStyle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      borderRadius: '10px',
      transform: 'translate(-50%, -50%)',
      width: '75%',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      fontFamily: fontFamilies.bold,
    },
    disclaimer: {
      fontSize: '10px',
      fontFamily: fontFamilies.lightItalic,
    },
    quantityInput: {
      marginTop: '10px',
      width: '60px',
      textAlign: 'center',
    },
    totalAmount: {
      marginTop: '10px',
      fontFamily: fontFamilies.bold,
      color: '#333',
    },
    purchaseSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '20px',
    },
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (confirmOpen) {
      const {current: descriptionElement} = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [confirmOpen]);

  const handleConfirmOpen = scrollType => () => {
    setConfirmOpen(true);
    setConfirmScroll(scrollType);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        setWalletAddress(addressArray[0]);
        console.log('Connected:', addressArray[0]);
      } catch (err) {
        console.error('Error connecting to MetaMask:', err);
        alert(translate(language, 'redeemItemDetail.errorConnectingMetaMask'));
      }
    } else if (window.web3) {
      const addressArray = await window.web3.eth.getAccounts();
      setWalletAddress(addressArray[0]);
      console.log('Connected:', addressArray[0]);
    } else {
      const userAgent = navigator.userAgent;
      const isMobile = /Mobi|Android/i.test(userAgent);

      if (isMobile) {
        const dappUrl = process.env.REACT_APP_API_URI.replace('https://', '');
        window.open(`https://metamask.app.link/dapp/${dappUrl}`, '_self');
      } else {
        alert(translate(language, 'redeemItemDetail.installMetaMask'));
      }
    }
  };

  const handleRedeem = async selectedVoucher => {
    if (selectedVoucher.type === 'BTO' && quantity < 100) {
      alert('Minimum quantity for BTO is 100.');
      return;
    }

    setLoading(true);
    try {
      if (selectedVoucher.type === 'BTO') {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URI}/api/transaction/create-checkout-session`,
          {productId: selectedVoucher._id, quantity},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (response.status === 200) {
          const sessionId = response.data.id;
          const stripe = await stripePromise;
          const {error} = await stripe.redirectToCheckout({
            sessionId: sessionId,
          });
          if (error) {
            console.error('Stripe error:', error);
          }
          return;
        }
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URI}/api/redeem/purchase/${selectedVoucher._id}`,
          {quantity, walletAddress},
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the authorization token in the headers
            },
          },
        );

        if (response.status === 200) {
          console.log(
            translate(language, 'redeemItemDetail.itemRedeemedSuccessfully'),
          );
          console.log(selectedVoucher);
          const message =
            translate(language, 'redeemItemDetail.itemRedeemedSuccessfully') +
            ':\n' +
            selectedVoucher?.name[language];
          navigate('/message', {
            state: {
              message,
              buttonText: translate(
                language,
                'redeemItemDetail.returnToRedeem',
              ),
              buttonLink: '/redeem',
            },
          });
        } else if (
          response.status === 403 &&
          response.data.message ===
            'Not enough points to redeem the requested quantity of this item.'
        ) {
          navigate('/message', {
            state: {
              message: translate(language, 'redeemItemDetail.notEnoughPoints'),
              buttonText: translate(
                language,
                'redeemItemDetail.returnToRedeem',
              ),
              buttonLink: '/redeem',
            },
          });
        } else {
          // Handle errors
          console.error('Redemption failed');

          navigate('/message', {
            state: {
              message: translate(
                language,
                'redeemItemDetail.redeemUnavailable',
              ),
              buttonText: translate(
                language,
                'redeemItemDetail.returnToRedeem',
              ),
              buttonLink: '/redeem',
            },
          });
        }
      }
    } catch (error) {
      console.error('Network error:', error);

      navigate('/message', {
        state: {
          message: translate(language, 'redeemItemDetail.redeemUnavailable'),
          buttonText: translate(language, 'redeemItemDetail.returnToRedeem'),
          buttonLink: '/redeem',
        },
      });
    } finally {
      setLoading(false); // End loading
    }
  };

  const disclaimerText = translate(language, 'redeemItemDetail.disclaimer');

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={styles.modalStyle}>
          <CloseIcon
            sx={{
              position: 'absolute',
              top: 8, // Adjust top spacing
              right: 8, // Adjust right spacing
              cursor: 'pointer', // Make it clear it's clickable
            }}
            style={{fontSize: '2rem'}}
            onClick={handleClose}
          />

          <h2 id="modal-modal-title">
            {selectedVoucher?.name?.[language] || ''}
          </h2>
          <p id="modal-modal-description">
            {translate(language, 'redeemItemDetail.description')}:{' '}
            {selectedVoucher?.description?.[language] || ''}
          </p>
          <p>
            {translate(language, 'redeemItemDetail.quotaLimits')}:{' '}
            {selectedVoucher?.quotaLimits || 'No Limits'}
          </p>
          <p>
            {translate(language, 'redeemItemDetail.availableToRedeem')}:{' '}
            {selectedVoucher?.availableToRedeem || 'No Limits'}
          </p>

          {selectedVoucher?.type === 'BTO' && (
            <div style={styles.purchaseSection}>
              <label>Enter the amount of {selectedVoucher.title.en}</label>
              <input
                type="number"
                value={quantity}
                onChange={e => setQuantity(e.target.value)}
                min="1"
                style={styles.quantityInput}
              />
              <p style={styles.totalAmount}>
                It will charge you {selectedVoucher.points * quantity} HKD{' '}
                <br />
                <br /> Press "Buy" to contiune
              </p>
            </div>
          )}

          {selectedVoucher?.type !== 'exchange' ? (
            <Button
              variant="contained"
              onClick={handleConfirmOpen('paper')}
              style={{marginTop: 16}}>
              {translate(language, 'redeemItemDetail.redeem')}
            </Button>
          ) : (
            <>
              {walletAddress ? (
                <>
                  <Typography
                    variant="body1"
                    style={{marginTop: 16, wordWrap: 'break-word'}}>
                    {translate(language, 'redeemItemDetail.walletConnected')}:{' '}
                    {walletAddress}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={handleConfirmOpen('paper')}
                    style={{marginTop: 16}}>
                    {translate(language, 'redeemItemDetail.redeem')}
                  </Button>
                </>
              ) : (
                <Button
                  variant="contained"
                  onClick={connectWallet}
                  style={{marginTop: 16}}>
                  {translate(language, 'redeemItemDetail.connectWallet')}
                </Button>
              )}
            </>
          )}
        </Box>
      </Modal>
      <Dialog
        open={confirmOpen}
        onClose={handleClose}
        scroll={confirmScroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
          {translate(language, 'redeemItemDetail.redeem')}:{' '}
          {selectedVoucher?.name?.[language] || ''}
          <br />
          <br />
          {translate(language, 'redeemItemDetail.confirmMessage')}
        </DialogTitle>
        <DialogContent dividers={confirmScroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}></DialogContentText>

          <Typography style={styles.disclaimer}>{disclaimerText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose}>
            {translate(language, 'redeemItemDetail.cancel')}
          </Button>
          <Button onClick={() => handleRedeem(selectedVoucher)}>
            {translate(language, 'redeemItemDetail.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default RedeemItemDetail;
