import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      textAlign: 'center',
      height: '80vh',
    },
    ImageContainer: {
      width: '100%',
      height: '190px',
      backgroundImage: 'url(./image.jpeg)',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    Text: {
      color: '#030303',
      fontFamily: fontFamilies.bold,
      margin: '20px 0',
    },
    SubText: {
      color: '#030303',
      fontFamily: fontFamilies.regular,
      margin: '10px 0',
    },
    UserInput: {
      width: '100%',
      height: '40px',
      margin: '10px 0',
      padding: '0px 16px',
      border: '1px solid #ccc',
      boxSizing: 'border-box',
      borderRadius: '20px',
      backgroundColor: '#ffffff',
      fontFamily: fontFamilies.regular,
    },
    link: {
      color: '#007bff',
      textDecoration: 'none',
      cursor: 'pointer',
      marginTop: '15px',
    },
    checkboxContainer: {
      alignSelf: 'flex-start',
      display: 'flex',
      alignItems: 'center',
      marginTop: '10px',
    },
    checkbox: {
      marginRight: '8px',
    },
    ForgotPasswordLabel: {
      width: '100%',
      height: '40px',
      marginTop: '20px',
      padding: '0px 16px',
      border: 'none',
      borderRadius: '20px',
      backgroundColor: '#cdeee3',
      color: '#000000',
      fontFamily: fontFamilies.regular,
      cursor: 'pointer',
    },
  };

  const validateEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\\.,;:\s@"]+\.)+[^<>()[\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const handleForgotPassword = async () => {
    try {
      setLoading(true);

      if (!validateEmail(email)) {
        setError(translate(language, 'forgotPassword.invalidEmail'));
        setLoading(false);
        return;
      }

      const response = await axios.post('/api/forgotPassword', {email});
      console.log(response.data);
      navigate('/home');
    } catch (error) {
      console.error(error);
      setError(translate(language, 'forgotPassword.error'));
      setLoading(false);
    }
  };

  return (
    <>
      <TopNav text={translate(language, 'forgotPassword.pageTitle')} />
      <div style={styles.container}>
        <div style={styles.Text}>
          {translate(language, 'forgotPassword.headerText')}
        </div>
        <div style={styles.SubText}>
          {translate(language, 'forgotPassword.subText')}
        </div>
        {error && <div style={{color: 'red'}}>{error}</div>}
        <input
          style={styles.UserInput}
          type="text"
          placeholder={translate(language, 'forgotPassword.emailPlaceholder')}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        {loading ? (
          <CircularProgress />
        ) : (
          <button
            style={styles.ForgotPasswordLabel}
            onClick={handleForgotPassword}>
            {translate(language, 'forgotPassword.submit')}
          </button>
        )}
      </div>
      <BottomNav />
    </>
  );
};

export default ForgotPassword;
