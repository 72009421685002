import {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';

const ArticlePage = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {language} = useContext(LanguageContext);
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    const fetchArticleDetail = async () => {
      setLoading(true);
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/article/detail/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setArticle(response.data);

        // Check if user has liked the article
        const userId = JSON.parse(localStorage.getItem('userInfo'))._id;
        setLiked(response.data.likedUsers.includes(userId));
      } catch (err) {
        console.error('Error fetching article details:', err);
        setError(translate(language, 'articlePage.error'));
      } finally {
        setLoading(false);
      }
    };

    fetchArticleDetail();
  }, [id, navigate, language]);

  const handleLike = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/article/like/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const userId = JSON.parse(localStorage.getItem('userInfo'))._id;
      setLiked(true);
      setArticle(prev => ({
        ...prev,
        likedUsers: [...prev.likedUsers, userId],
      }));
    } catch (err) {
      console.error('Error liking article:', err);
    }
  };

  const handleUnlike = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/article/unlike/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const userId = JSON.parse(localStorage.getItem('userInfo'))._id;
      setLiked(false);
      setArticle(prev => ({
        ...prev,
        likedUsers: prev.likedUsers.filter(id => id !== userId),
      }));
    } catch (err) {
      console.error('Error unliking article:', err);
    }
  };

  const likeIcon = liked ? (
    <FavoriteIcon
      sx={{color: '#2A1662', cursor: 'pointer'}}
      onClick={handleUnlike}
    />
  ) : (
    <FavoriteBorderIcon
      sx={{color: '#2A1662', cursor: 'pointer'}}
      onClick={handleLike}
    />
  );

  const styles = {
    articleContainer: {
      padding: '20px',
      textAlign: 'center',
    },
    articleImage: {
      width: '100%',
      borderRadius: '10px',
    },
    interactionContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginTop: '10px',
    },
    interactionItem: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '10px',
      color: '#2A1662',
    },
    articleContent: {
      textAlign: 'left',
      marginTop: '20px',
    },
  };

  if (loading) {
    return (
      <div>
        <TopNav text={translate(language, 'articlePage.title')} />
        <div style={styles.articleContainer}>
          <h1>
            <Skeleton width={200} />
          </h1>
          <Skeleton height={300} />
          <div style={styles.interactionContainer}>
            <Skeleton width={100} height={40} />
            <Skeleton width={100} height={40} />
          </div>
          <div style={styles.articleContent}>
            <Skeleton count={5} />
          </div>
          <small>
            <Skeleton width={150} />
          </small>
        </div>
        <BottomNav />
      </div>
    );
  }

  if (error) return <p>{translate(language, 'articlePage.error')}</p>;

  return (
    <div>
      <TopNav text={translate(language, 'articlePage.title')} />
      {article && (
        <div style={styles.articleContainer}>
          <h1>{article.title[language]}</h1>
          <img
            src={article.coverImg[language]}
            alt="Cover"
            style={styles.articleImage}
          />
          <div style={styles.interactionContainer}>
            <div style={styles.interactionItem}>
              <VisibilityIcon sx={{color: '#2A1662'}} />{' '}
              {article.readUsers.length}
            </div>
            <div style={styles.interactionItem}>
              {likeIcon} {article.likedUsers.length}
            </div>
          </div>
          <div style={styles.articleContent}>
            <div
              dangerouslySetInnerHTML={{__html: article.content[language]}}
            />
          </div>
          <small>
            {translate(language, 'articlePage.postedOn')}:{' '}
            {new Date(article.created).toLocaleDateString()}
          </small>
        </div>
      )}
      <BottomNav />
    </div>
  );
};

export default ArticlePage;
