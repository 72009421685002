// Remarks: Don't use the font family here directly, use the font family from useFontFamilyByLanguage hook instead.

export interface IFontFamily {
  bold: string;
  boldItalic: string;
  semiBold: string;
  semiBoldItalic: string;
  regular: string;
  regularItalic: string;
  light: string;
  lightItalic: string;
  medium: string;
  mediumItalic: string;
}

export const EnglishFontFamily: IFontFamily = {
  bold: 'Poppins-Bold',
  boldItalic: 'Poppins-BoldItalic',
  semiBold: 'Poppins-SemiBold',
  semiBoldItalic: 'Poppins-SemiBoldItalic',
  regular: 'Poppins-Regular',
  regularItalic: 'Poppins-RegularItalic',
  light: 'Poppins-Light',
  lightItalic: 'Poppins-LightItalic',
  medium: 'Poppins-Medium',
  mediumItalic: 'Poppins-MediumItalic',
};

export const TraditionalChineseFontFamily: IFontFamily = {
  bold: 'NotoSansTC-Black',
  boldItalic: 'NotoSansTC-Black',
  semiBold: 'NotoSansTC-Bold',
  semiBoldItalic: 'NotoSansTC-Bold',
  regular: 'NotoSansTC-Regular',
  regularItalic: 'NotoSansTC-Regular',
  light: 'NotoSansTC-Light',
  lightItalic: 'NotoSansTC-Light',
  medium: 'NotoSansTC-Medium',
  mediumItalic: 'NotoSansTC-Medium',
};

export const SystemFonts = Object.values(EnglishFontFamily);

export default SystemFonts;
