import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import NotificationsIcon from '@mui/icons-material/Notifications';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';
import {useContext, useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {useNavigate} from 'react-router-dom';
import Slider from 'react-slick';
import {ReactSVG} from 'react-svg';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import BottomNav from '../Navigation/BottomNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';

function Home() {
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);

  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem('userInfo')) || {},
  );
  const [Points, setPoints] = useState(userInfo.points || 0);
  const [whatsNewItems, setWhatsNewItems] = useState([]);
  const [activityItems, setActivityItems] = useState([]);
  const [likedArticles, setLikedArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.log('No token found');
          return; // Optionally handle the situation by redirecting to login or showing an error
        }
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/auth/getUserInfo`,
          config,
        );
        setUserInfo(response.data.userInfo);
        setPoints(response.data.userInfo.points);

        // Update localStorage
        localStorage.setItem('token', response.data.token);
        localStorage.setItem(
          'userInfo',
          JSON.stringify(response.data.userInfo),
        );
      } catch (error) {
        console.error('Failed to fetch user info:', error);
        // Optionally handle errors, like redirecting to login or showing an error message
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    const fetchWhatsNewItems = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.log('No token found, redirecting to login...');
        navigate('/login');
        return;
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/article/list?type=news`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setWhatsNewItems(response.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } catch (error) {
        console.error('Error fetching new items:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    };

    fetchWhatsNewItems();

    // Function to initialize push notifications
    function initializePushNotifications() {
      // Check if the browser supports notifications
      if (!('Notification' in window)) {
        console.log('This browser does not support notifications.');
        return;
      }

      // Check the current Notification permission status
      if (Notification.permission === 'granted') {
        // The user has already granted permission, you can subscribe the user
        subscribeUser();
      } else if (Notification.permission === 'denied') {
        // The user has denied permission
        console.log('User has denied notifications.');
      } else {
        // The user hasn't been asked yet or they have dismissed the prompt
        requestPermission();
      }
    }

    // Function to request notification permission
    function requestPermission() {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          // The user granted permission
          subscribeUser();
        } else if (permission === 'denied') {
          // The user denied permission
          console.log('User denied notifications.');
        } else {
          // The user dismissed the permission prompt
          console.log('User dismissed the permission prompt.');
        }
      });
    }

    // Function to subscribe the user to push notifications
    function subscribeUser() {
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker.ready.then(function (registration) {
          const applicationServerKey = urlB64ToUint8Array(
            process.env.REACT_APP_VAPID_PUBLIC_KEY,
          );
          registration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: applicationServerKey,
            })
            .then(function (subscription) {
              console.log('User is subscribed:', subscription);
              sendSubscriptionToServer(subscription);
            })
            .catch(function (err) {
              console.log('Failed to subscribe the user:', err);
            });
        });
      }
    }

    // Helper function to convert VAPID key
    function urlB64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    }

    // Function to send the subscription object to your server
    function sendSubscriptionToServer(subscription) {
      const token = localStorage.getItem('token');
      axios
        .post(
          `${process.env.REACT_APP_API_URI}/api/auth/subscribe`,
          {subscription},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(response => {
          if (!response.data.success) {
            throw new Error('Bad response from server.');
          }
        })
        .catch(error => {
          console.error('Error sending subscription to server:', error);
        });
    }
    // Initialize push notifications when the page loads
    initializePushNotifications();
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('No token found, redirecting to login...');
      navigate('/login');
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const fetchActivityItems = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/article/list?type=activities`,
          config,
        );
        setActivityItems(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching activity items:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    };

    fetchActivityItems();
  }, [navigate]);

  const handleLike = async articleId => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/article/like/${articleId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setLikedArticles(prevLikedArticles => [...prevLikedArticles, articleId]);
    } catch (err) {
      console.error('Error liking article:', err);
    }
  };

  const handleUnlike = async articleId => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/article/unlike/${articleId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setLikedArticles(prevLikedArticles =>
        prevLikedArticles.filter(id => id !== articleId),
      );
    } catch (err) {
      console.error('Error unliking article:', err);
    }
  };

  const renderLikeIcon = articleId => {
    return likedArticles.includes(articleId) ? (
      <FavoriteIcon color="secondary" onClick={() => handleUnlike(articleId)} />
    ) : (
      <FavoriteBorderIcon onClick={() => handleLike(articleId)} />
    );
  };

  const styles = {
    mainContainer: {
      marginTop: '0px',
      marginLeft: '35px',
      marginRight: '35px',
      marginBottom: '0px',
      fontFamily: fontFamilies.regular,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'fixed',
      top: 0, // Align to the top of the viewport
      left: 0, // Align to the left of the viewport
      right: 0, // Align to the right of the viewport
      margin: 0,
      backgroundColor: 'rgb(240, 240, 240)',
      width: '100%',
      zIndex: 1000, // Ensure it's above other elements
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    title: {
      fontFamily: fontFamilies.bold,
      color: '#2A1662',
      fontSize: '24px',
      margin: '15px',
    },
    scanIconButton: {
      cursor: 'pointer',
      background: 'none',
      border: 'none',
    },
    introContainer: {
      backgroundColor: '#2A1662',
      padding: '10px 20px',
      borderRadius: '20px',
      fontFamily: fontFamilies.regular,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      marginBottom: '50px',
      marginTop: '170px',
      color: 'white',
    },
    greetingContainer: {
      display: 'flex',
      flexDirection: 'row', // Explicitly set, although it's the default
      alignItems: 'center',
      justifyContent: 'space-between', // Adjusts space between the two child divs
      padding: '10px 20px',
      fontFamily: fontFamilies.regular,
      marginBottom: '10px',
    },
    greetingMsg: {
      fontSize: '18px',
      fontFamily: fontFamilies.semiBold,
    },
    greetingIcon: {
      fontSize: '12px',
      fontFamily: fontFamilies.regular,
      marginTop: '-100px',
      marginRight: '-35px',
    },
    pointsContainer: {
      display: 'flex',
      flexDirection: 'column', 
      alignItems: 'center', // This centers children horizontally in the flex container
      justifyContent: 'center', // This centers children vertically in the flex container
      backgroundColor: '#87FFDB',
      padding: '10px 20px',
      borderRadius: '20px',
      fontSize: '12px',
      fontFamily: fontFamilies.regular,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      marginBottom: '10px',
    },
    pointsLabel: {
      fontSize: '12px',
      fontFamily: fontFamilies.regular,
    },
    points: {
      fontSize: '18px',
      fontFamily: fontFamilies.semiBold,
      display: 'flex',
      alignItems: 'center', // Align text and SVG icon
    },
    coinIcon: {
      height: '25px',
      width: '25px',
      marginRight: '5px',
    },
    BannerHeading: {
      fontFamily: fontFamilies.semiBold,
      fontSize: '16px',
    },
    BannerContainer: {
      marginLeft: '-35px',
      marginRight: '-35px',
      color: 'rgb(30, 30, 30)',
    },
    BannerImage: {
      width: '70vw',
      height: 'auto',
      borderRadius: '20px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      position: 'relative', // Add position relative for the overlay
    },
    BannerTitle: {
      width: '70vw',
      fontFamily: fontFamilies.regular,
      fontSize: '12px',
      margin: '10px',
    },
    ImageContainer: {
      top: '138px',
      left: '22px',
      width: '329px',
      height: '118px',
      backgroundImage: 'url(./image.png)',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    Icon: {
      color: '#030303',
      fill: '#030303',
      width: '40px',
      height: '40px',
    },
    homeContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '60px', // Adjust this value to match your TopNav's actual height plus some margin
    },
    greeting: {
      fontFamily: fontFamilies.semiBold,
      textAlign: 'center',
      margin: '20px 0',
    },
    banner: {
      width: '100%',
      margin: '20px 0',
      // other styles for the banner
    },
    whatsNewSection: {
      width: '100%',
      // other styles for the what's new section
      fontFamily: fontFamilies.semiBold,
    },
    activitiesSection: {
      width: '100%',
      // other styles for the activities section
    },
    sectionHeader: {
      fontFamily: fontFamilies.semiBold,
      padding: '10px 0',
    },
    interactionOverlay: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '-30px',
      paddingRight: '60px',
      color: '#fff', // Set the icon and text color to white for better visibility
    },
    interactionItem: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '10px',
      zIndex: '1',
    },
  };

  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    centerMode: true, // Enable center mode
    centerPadding: '40px',
    responsive: [],
  };

  for (let i = 1; i < 10; i++) {
    const breakpointJson = {
      breakpoint: window.innerWidth * i,
      settings: {
        slidesToShow: i,
        slidesToScroll: 1,
        infinite: false,
      },
    };
    settings.responsive[i] = breakpointJson;
  }

  const PointsRightArrowIcon = () => (
    <svg
      height="15" // Adjust the size as needed
      width="15" // Adjust the size as needed
      viewBox="0 0 20 20" // Ensure your viewBox matches the path's coordinates
    >
      <path
        d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z"
        fill="currentColor" // This will take the color from the 'color' style of the component
      />
    </svg>
  );

  const handleNavigateToPoints = () => {
    navigate('/points', {state: {showBackIcon: true}});
  };

  return (
    <>
      <div style={styles.mainContainer}>
        <div style={styles.header}>
          <img
            src="/assets/images/logo.svg"
            alt="Boredtech"
            style={styles.title}
          />
          <div style={styles.scanIconContainer}>
            <NotificationsIcon
              style={{
                fontSize: '24px',
                marginTop: '15px',
                marginBottom: '15px',
                marginRight: '15px',
              }}
              onClick={() =>
                navigate('/notifications', {state: {showBackIcon: true}})
              }
            />
          </div>
        </div>

        <section>
          <div style={styles.introContainer}>
            <div style={styles.greetingContainer}>
              <div style={styles.greetingMsg}>
                {translate(language, 'home.greetings').replace(
                  '{userName}',
                  userInfo ? userInfo.firstName : 'User',
                )}
              </div>
              <div style={styles.greetingIcon}>
                <img src="/assets/images/greeting.png" alt="greeting" />
              </div>
            </div>
            <div
              style={styles.pointsContainer}
              onClick={handleNavigateToPoints}>
              <div style={styles.pointsLabel}>
                {translate(language, 'home.pointsLabel')}
                <PointsRightArrowIcon />
              </div>
              <div style={styles.points}>
                <ReactSVG
                  src="/assets/icons/coins.svg"
                  style={styles.coinIcon}
                />
                {Points}
              </div>
            </div>
          </div>
        </section>

        <div style={styles.BannerHeading}>
          <h2>{translate(language, 'home.whatsNew')}</h2>
          {loading ? (
            <Skeleton height={150} width={200} count={3} />
          ) : (
            <Slider {...settings} style={styles.BannerContainer}>
              {whatsNewItems.map(item => (
                <div key={item._id}>
                  <img
                    src={item.coverImg[language]}
                    alt={item.title[language]}
                    style={styles.BannerImage}
                    onClick={() =>
                      navigate(`/article/${item._id}`, {
                        state: {showBackIcon: true},
                      })
                    }
                  />
                  <div style={styles.interactionOverlay}>
                    <div style={styles.interactionItem}>
                      <VisibilityIcon /> {item.readUsers.length}
                    </div>
                    <div style={styles.interactionItem}>
                      {renderLikeIcon(item._id)} {item.likedUsers.length}
                    </div>
                  </div>
                  <div style={styles.BannerTitle}>
                    <h3 style={{margin: '0px'}}>{item.title[language]}</h3>
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>

        <div style={styles.BannerHeading}>
          <h2>{translate(language, 'home.activities')}</h2>
          {loading ? (
            <Skeleton height={150} width={200} count={3} />
          ) : (
            <Slider {...settings} style={styles.BannerContainer}>
              {activityItems.map(item => (
                <div key={item._id}>
                  <img
                    src={item.coverImg[language]}
                    alt={item.title[language]}
                    style={styles.BannerImage}
                    onClick={() =>
                      navigate(`/article/${item._id}`, {
                        state: {showBackIcon: true},
                      })
                    }
                  />
                  <div style={styles.interactionOverlay}>
                    <div style={styles.interactionItem}>
                      <VisibilityIcon /> {item.readUsers.length}
                    </div>
                    <div style={styles.interactionItem}>
                      {renderLikeIcon(item._id)} {item.likedUsers.length}
                    </div>
                  </div>
                  <div style={styles.BannerTitle}>
                    <h3 style={{margin: '0px'}}>{item.title[language]}</h3>
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>

      <BottomNav />
    </>
  );
}

export default Home;
