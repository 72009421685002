import axios from 'axios';
import {useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {useNavigate} from 'react-router-dom';

const MyLikes = ({language, styles}) => {
  const [likedArticles, setLikedArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLikedArticles = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/article/liked`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setLikedArticles(response.data);
      } catch (err) {
        console.error('Error fetching liked articles:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchLikedArticles();
  }, [navigate]);

  const handleCardClick = articleId => {
    navigate(`/article/${articleId}`, {state: {showBackIcon: true}});
  };

  return (
    <div style={styles.articlesContainer}>
      {loading ? (
        <>
          <div style={styles.articleCard}>
            <Skeleton height={150} />
            <Skeleton height={20} width="80%" style={{marginTop: '10px'}} />
          </div>
          <div style={styles.articleCard}>
            <Skeleton height={150} />
            <Skeleton height={20} width="80%" style={{marginTop: '10px'}} />
          </div>
          <div style={styles.articleCard}>
            <Skeleton height={150} />
            <Skeleton height={20} width="80%" style={{marginTop: '10px'}} />
          </div>
        </>
      ) : (
        likedArticles.map(article => (
          <div
            key={article._id}
            style={styles.articleCard}
            onClick={() => handleCardClick(article._id)}>
            <img
              src={article.coverImg[language]}
              alt={article.title[language]}
              style={styles.articleImage}
            />
            <h3 style={styles.articleTitle}>{article.title[language]}</h3>
          </div>
        ))
      )}
    </div>
  );
};

export default MyLikes;
