import {
  EnglishFontFamily,
  TraditionalChineseFontFamily,
} from '../constants/Fonts';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

export const useFontFamilyByLanguage = () => {
  const {
    i18n: {language},
  } = useTranslation();

  return useMemo(() => {
    switch (language) {
      case 'zh':
        return {
          fontFamilies: TraditionalChineseFontFamily,
          systemFonts: Object.values(TraditionalChineseFontFamily),
        };
      default:
        return {
          fontFamilies: EnglishFontFamily,
          systemFonts: Object.values(EnglishFontFamily),
        };
    }
  }, [language]);
};
