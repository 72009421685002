// contexts/LanguageProvider.js
import {createContext, useEffect, useState} from 'react';
import translations from '../contexts/translations.json';

export const LanguageContext = createContext();

const LanguageProvider = ({children}) => {
  const [language, setLanguage] = useState(
    localStorage.getItem('Language') || 'en',
  );

  useEffect(() => {
    localStorage.setItem('Language', language);
  }, [language]);

  const switchLanguage = lang => {
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{language, switchLanguage, translations}}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
